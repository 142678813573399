import { Modal } from "../../../components/Modal";
import cardano from "../../../assets/icons/big_cardano.svg";
import cardano2 from "../../../assets/icons/cardano.svg";
import diamond from "../../../assets/icons/diamond-512.svg";
import arrow from "../../../assets/icons/big_arrow-right.svg";
import styles from "./index.module.scss";
import { ReactSVG } from "react-svg";
import { ClosedBond2, WithClosedBondLender } from "../../../types";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  redeemJboBondTokens,
  selectRedeemJboBondTokensResponse,
} from "../../../bond/actions";
import { jboTxResponseToAlert, resize } from "../../../bond/utils";
import { setAlert } from "../../../store/slices/alertSlice";
import { useEffect, useRef, useState } from "react";
import {
  formatAmount,
  formatValue,
  getBondPosition,
  getUserBondPositions,
} from "../../../bond/getters/slice";
import { SpinnerModal } from "../SpinnerModal";
import { updateWalletUtxosThunk } from "../../../store/slices/walletSlice";
import { Button } from "../../../components/ui/button";
import { Card } from "../../../components/ui/card";
import { Text } from "../../../components/ui/typography";

interface Props {
  onClose?: () => void;
  isOpen: boolean;
  setIsOpen: (predicate: boolean) => void;
  bond: WithClosedBondLender<ClosedBond2>;
  txSigningMessage?: string;
}

export const RedeemModal = ({
  isOpen,
  setIsOpen,
  bond,
  onClose,
  txSigningMessage,
}: Props) => {
  const dispatch = useAppDispatch();

  const [isSpinnerModalOpen, setIsSpinnerModalOpen] = useState(false);

  const redeemBondTokensResponse = useAppSelector(
    selectRedeemJboBondTokensResponse
  );

  const prev = useRef({ redeemBondTokensResponse });

  useEffect(() => {
    if (prev.current.redeemBondTokensResponse === redeemBondTokensResponse) {
      prev.current = { redeemBondTokensResponse };
    } else {
      dispatch(setAlert(jboTxResponseToAlert(redeemBondTokensResponse)));
      if (
        redeemBondTokensResponse !== undefined &&
        redeemBondTokensResponse.tag === "JboTxSuccess"
      ) {
        (async () => {
          dispatch(updateWalletUtxosThunk(null));
          dispatch(getBondPosition(bond.uniqTokenName));
          dispatch(getUserBondPositions());
        })();
        setIsOpen(false);
      }
      setIsSpinnerModalOpen(false);
    }
  }, [dispatch, bond.uniqTokenName, redeemBondTokensResponse]);

  const buttonAction = () => {
    const params = {
      closedBondAddress: bond.address,
      uniqNftPolicyId: bond.uniqCurrencySymbol,
      uniqNftTokenName: bond.uniqTokenName,
    };
    dispatch(redeemJboBondTokens(params));
    setIsSpinnerModalOpen(true);
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <h3 className="text-2xl">Redeem</h3>
        <div className={styles.icons}>
          <ReactSVG beforeInjection={resize(100, 100)} src={diamond} />
          <ReactSVG src={arrow} />
          <ReactSVG src={cardano} />
        </div>
        <Button className="w-full" onClick={buttonAction}>
          Redeem
        </Button>
        <Card className="flex items-center justify-between mt-6">
          <div>
            <Text tone="muted">You have</Text>
            <Text size="medium">
              {formatAmount(bond.user.walletBondTokenAmount)}
              Bond token
            </Text>
          </div>
          <div className={styles.rightSide}>
            <div>
              <Text tone="muted">You get</Text>
              <Text size="medium">
                {formatValue(bond.user.redemptionValueAsLovelace)}₳
              </Text>
            </div>
          </div>
        </Card>
      </Modal>
      <SpinnerModal open={isSpinnerModalOpen} message={txSigningMessage} />
    </>
  );
};
