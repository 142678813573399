import { FC } from "react";
import classNames from "classnames";
import styles from "./index.module.scss";
import {Spinner} from "../../../components/Spinner";

type Props = {
  open: boolean,
  message?: string,
};

export const SpinnerModal: FC<Props> = ({open, message}) => {
  console.log('SpinnerModal')
  return (
    <>
    <div
      className={classNames(
        styles.backdrop,
        { [styles.blur]: true },
        { [styles.hidden]: !open }
      )}
    >
      <Spinner/>
      {message && <div className={classNames(styles.message)}>{message}</div>}
    </div>
    </>
  );
};
 
