import { Assets, OutputData, Redeemer, Script, slotToBeginUnixTime, SLOT_CONFIG_NETWORK, UTxO } from "lucid-cardano";
import { LucidExt, TxExt } from "./lucid-ext";

export type TxInSpend = {
  txIn: TxIn;
  txInWitness: TxInWitness;
};

export type TxIn = {
  utxoRef: UtxoRef;
  txOut: TxOut;
};

export type TxInWitness =
  | {
    tag: "TxInKeyWitness";
  }
  | {
    tag: "TxInScriptWitness";
    contents: { scriptCbor: string; redeemer: string; datum: string };
  };

export type TxOut = {
  address: string;
  value: { [assetClass: string]: bigint };
  datum: Datum | null;
  refScript: string | null;
};

// assetClass: policyId.tokenName
export type Value = {
  [assetClass: string]: bigint;
};

export type Datum = {
  cbor: string;
  isInline: boolean;
};

// utxoRef: txHash#index
export type UtxoRef = string;

export type Mint = {
  policyId: string;
  policyCbor: string;
  redeemerCbor: string;
  tokenNameToAmountMap: { [tokenName: string]: bigint };
};

export type TxRecipe = {
  txInSpends: TxInSpend[];
  txInRefs: TxIn[];
  mints: Mint[];
  txOuts: TxOut[];
  validityStartSlot: bigint | null;
  validityEndSlot: bigint | null;
  txMetadata: string | null;
  requiredSignerPkhs: string[];
};

const valueToLucidAssets = (value: Value): Assets => {
  // TODO
  const assets: Assets = {};
  for (const [assetClass, amount] of Object.entries(value)) {
    const [policyId, tokenName] = assetClass.split(".");
    if (policyId === "lovelace") {
      assets["lovelace"] = BigInt(amount);
    } else {
      assets[`${policyId}${tokenName}`] = BigInt(amount);
    }
  }
  return assets;
};

const mintToLucidMint = (mint: Mint): [Assets, Redeemer, Script] => {
  const assets: Assets = {}
  const policyId = mint.policyId;
  const policyCbor = mint.policyCbor;
  const redeemerCbor = mint.redeemerCbor;
  for (
    const [tokenName, amount] of Object.entries(mint.tokenNameToAmountMap)
  ) {
    assets[`${policyId}${tokenName}`] = amount;
  }
  const policy: Script = {
    type: "PlutusV2" as const,
    script: policyCbor,
  };
  return [assets, redeemerCbor, policy]
}

const mintsToLucidMints = (mints: Mint[]): [Assets, Redeemer, Script][] => {
  const lucidMints: [Assets, Redeemer, Script][] = []
  for (const mint of mints) {
    lucidMints.push(mintToLucidMint(mint))
  }
  return lucidMints;
};

const txInSpendToUTxOWitnessPair = (
  spend: TxInSpend,
): [UTxO, TxInWitness] => {
  const utxo = txInToUTxO(spend.txIn);
  const witness = spend.txInWitness;
  return [utxo, witness];
};

const utxoRefToPair = (utxoRef: UtxoRef): [string, number] => {
  // NOTE: if this errors it's a bug
  const [txHash, outputIndex] = utxoRef.split("#");
  return [txHash, Number(outputIndex)];
};

const txInToUTxO = (txIn: TxIn): UTxO => {
  const [txHash, outputIndex] = utxoRefToPair(txIn.utxoRef);
  const txOut = txIn.txOut;
  let refScript: Script | null = null;
  if (txOut.refScript) {
    refScript = {
      type: "PlutusV2" as const,
      script: txOut.refScript,
    };
  }
  return {
    txHash,
    outputIndex,
    address: txOut.address,
    assets: valueToLucidAssets(txOut.value),
    datum: txOut.datum?.cbor,
    scriptRef: refScript,
  };
};

// 0. Tx.complete() is monkey patched so that it can take (virtual) utxos that we give it
// 1. we must also make sure to give it a change address (this is the default)
// 2. we must also make sure to use nativeUplc (this is the default)
// The above allows this function to be almost pure when `complete()` is called.
export const txRecipeToTx =
  (lucid: LucidExt) => (txRecipe: TxRecipe): TxExt => {
    let tx = lucid.newTx();

    const spends = txRecipe.txInSpends.map(txInSpendToUTxOWitnessPair);
    const validators: Script[] = [];
    for (const [utxo, witness] of spends) {
      let redeemer: Redeemer | undefined = undefined;
      if (witness.tag === "TxInScriptWitness") {
        redeemer = witness.contents.redeemer;
        const validator = {
          type: "PlutusV2" as const,
          script: witness.contents.scriptCbor,
        };
        validators.push(validator);
      }
      tx = tx.collectFrom([utxo], redeemer);
    }

    for (const validator of validators) {
      tx = tx.attachSpendingValidator(validator);
    }

    const txInRefUtxos = txRecipe.txInRefs.map(txInToUTxO);
    tx = tx.readFrom(txInRefUtxos);

    const lucidMints = mintsToLucidMints(txRecipe.mints);
    lucidMints.forEach(([assets, redeemer, policy]) => {
      tx = tx.mintAssets(assets, redeemer);
      tx = tx.attachMintingPolicy(policy);
    })

    for (const txOut of txRecipe.txOuts) {
      const address = txOut.address;
      const assets = valueToLucidAssets(txOut.value);
      const outputData: OutputData = {};
      if (txOut.datum) {
        if (txOut.datum.isInline) {
          outputData.inline = txOut.datum.cbor;
        } else {
          outputData.asHash = txOut.datum.cbor;
        }
        const scriptRef = txOut.refScript === null
          ? undefined
          : { type: "PlutusV2" as const, script: txOut.refScript };
        outputData.scriptRef = scriptRef;
      }
      tx = tx.payToAddressWithData(address, outputData, assets);
    }

    if (txRecipe.validityStartSlot !== null) {
      const slotConfig = SLOT_CONFIG_NETWORK[lucid.network];
      tx = tx.validFrom(
        slotToBeginUnixTime(Number(txRecipe.validityStartSlot), slotConfig),
      );
    }

    if (txRecipe.validityEndSlot !== null) {
      const slotConfig = SLOT_CONFIG_NETWORK[lucid.network];
      tx = tx.validFrom(
        slotToBeginUnixTime(Number(txRecipe.validityEndSlot), slotConfig),
      );
    }

    for (const pkh of txRecipe.requiredSignerPkhs) {
      tx = tx.addSignerKey(pkh);
    }

    return tx;
  };

