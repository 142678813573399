import ReactSlider, { ReactSliderProps } from "react-slider";
import "./index.css";

interface Props extends ReactSliderProps {}

export const Slider = ({ onChange, value, min, max, step }: Props) => {
  return (
    <ReactSlider
      className="horizontal-slider"
      thumbClassName="thumb"
      trackClassName="track"
      {...{ min, max, step, value, onChange }}
    />
  );
};
