import React from "react";
import styles from "../index.module.scss";
import { Icon } from "../../../components/Icon";
import classNames from "classnames";
import {
  IssueBondFormSection,
  IssueBondFormSectionHeader,
} from "../../../components/ui/IssueBondForm";
import { ConnectWallet } from "../../Topbar/ConnectWallet";
import { useLedgerWallet } from "../../../store/hooks/ledgerWallet";
import { Card } from "../../../components/ui/card";
import { FiCopy } from "react-icons/fi";
import { Text } from "../../../components/ui/typography";

interface ConnectWalletProps {
  walletAddress?: string;
  isConnected: boolean;
}

export const ConnectWalletSection: React.FC<ConnectWalletProps> = (props) => {
  const { walletAddress, isConnected } = props;
  const ledgerHandle = useLedgerWallet();

  const { connectLedger } = ledgerHandle;

  return (
    <IssueBondFormSection>
      <IssueBondFormSectionHeader title="Connect Wallet" />
      {walletAddress ? (
        <Card className="px-5 py-4 flex gap-3 items-center">
          <Icon name="wallet" className="self-start" />
          <div>
            <Text onClick={() => navigator.clipboard.writeText(walletAddress)}>
              {walletAddress.slice(0, 8)}...{walletAddress.slice(-8)}
              <FiCopy className="h-4 w-4 inline-flex ml-1" />
            </Text>
            <Text tone="muted">Eternl Wallet</Text>
          </div>

          <div className="ml-auto text-ui-base-green bg-[hsla(138,39%,52%,0.1)] text-xs rounded-2xl px-2 py-0.5 font-medium leading-[1.5]">
            {isConnected ? "Connected" : ""}
          </div>
        </Card>
      ) : (
        <div className="w-full flex">
          <ConnectWallet fullWidth connectLedger={connectLedger} />
        </div>
      )}
    </IssueBondFormSection>
  );
};
