import { useState, useEffect, useRef } from "react";
import styles from "./index.module.scss";
import { Info } from "../../../components/Info";
import classNames from "classnames";
import { Slider } from "../../../components/Slider";
import { ModalInput } from "../../BondPage/ModalInput";
import { Modal } from "../../../components/Modal";
import { OpenedBond2 } from "../../../types";
import Big from "big.js";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  selectWalletLovelaceAmount,
  updateWalletUtxosThunk,
} from "../../../store/slices/walletSlice";
import {
  formatValue,
  getBondPosition,
  getUserBondPositions,
} from "../../../bond/getters/slice";
import {
  addMargin,
  selectAddMarginJboBondResponse,
} from "../../../bond/actions";
import { bondFaceValueAsLovelace } from "../../../bond/getters/ui";
import { jboTxResponseToAlert } from "../../../bond/utils";
import { setAlert } from "../../../store/slices/alertSlice";
import { SpinnerModal } from "../SpinnerModal";
import { Text } from "../../../components/ui/typography";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";

interface Props {
  isOpen: boolean;
  setIsOpen: (predicate: boolean) => void;
  bond: OpenedBond2;
  onClose?: () => void;
  txSigningMessage?: string;
}

export const PayInterestModal = ({
  isOpen,
  setIsOpen,
  bond,
  onClose,
  txSigningMessage,
}: Props) => {
  console.log("PayInterestModal");
  const dispatch = useAppDispatch();
  // if (bond.currInterestBufferAsEpochs !== null) {
  //   console.log(bond)
  //   console.log(bond.currInterestBufferAsEpochs.toNumber())
  // }

  const [isSpinnerModalOpen, setIsSpinnerModalOpen] = useState(false);

  useEffect(() => {
    dispatch(updateWalletUtxosThunk(null));
  });

  const marginAddResponse = useAppSelector(selectAddMarginJboBondResponse);

  const prev = useRef({ marginAddResponse });

  const initialCurrentInterestBufferAsEpochs =
    bond.currInterestBufferAsEpochs === null
      ? 0
      : bond.currInterestBufferAsEpochs.round(0, Big.roundDown).toNumber();

  useEffect(() => {
    setCurrentInterest(initialCurrentInterestBufferAsEpochs);
    setUpdatedInterest(initialCurrentInterestBufferAsEpochs);
  }, [initialCurrentInterestBufferAsEpochs]);

  useEffect(() => {
    if (prev.current.marginAddResponse === marginAddResponse) {
      prev.current = { marginAddResponse };
    } else {
      dispatch(setAlert(jboTxResponseToAlert(marginAddResponse)));
      if (
        marginAddResponse !== undefined &&
        marginAddResponse.tag === "JboTxSuccess"
      ) {
        (async () => {
          dispatch(getBondPosition(bond.uniqTokenName));
          dispatch(getUserBondPositions());
          dispatch(updateWalletUtxosThunk(null));
          setIsOpen(false);
        })();
        setUpdatedInterest(initialCurrentInterestBufferAsEpochs);
        setCurrentInterest(initialCurrentInterestBufferAsEpochs);
        setIsOpen(false);
      }
      setIsSpinnerModalOpen(false);
      setInterestValueAsEpochs(0);
    }
  }, [dispatch, marginAddResponse]);

  const bondTokenName = bond.uniqTokenName;

  const walletAmountAsLovelace = useAppSelector(selectWalletLovelaceAmount);

  const minSliderValue = 0;
  const [interestValueAsEpochs, setInterestValueAsEpochs] = useState(0);
  const [currentInterest, setCurrentInterest] = useState(
    initialCurrentInterestBufferAsEpochs
  );
  const [updatedInterest, setUpdatedInterest] = useState(
    initialCurrentInterestBufferAsEpochs
  );
  // console.log(initialCurrentInterestBufferAsEpochs)
  // console.log(currentInterest)
  // console.log(updatedInterest)

  const rewardsPerEpochAsLovelace = bond.rewardsPerEpochAsLovelace;
  const maxDurationAsEpochs = bond.maxDurationAsEpochs;
  const premiumPaidAsLovelace = bond.totalPremiumPaidAsLovelace;
  // TODO: remove the addition of 1 lovelace when new validation scripts are in
  const totalPremiumAsLovelace = rewardsPerEpochAsLovelace
    .mul(maxDurationAsEpochs)
    .add(1);
  const remainingPremiumAsLovelaceRaw = totalPremiumAsLovelace.sub(
    premiumPaidAsLovelace
  );
  const remainingPremiumAsLovelace = remainingPremiumAsLovelaceRaw.lt(Big(0))
    ? Big(0)
    : remainingPremiumAsLovelaceRaw;
  const remainingPremiumAsEpochs = rewardsPerEpochAsLovelace.eq(Big(0))
    ? null
    : remainingPremiumAsLovelace
        .div(rewardsPerEpochAsLovelace)
        .round(0, Big.roundUp);
  const utxoValueAsLovelace = bond.totalPremiumPaidAsLovelace.add(
    bond.totalBondTokenAmount.mul(bondFaceValueAsLovelace)
  );

  const maxSliderValue =
    remainingPremiumAsEpochs === null
      ? 0
      : remainingPremiumAsLovelace.gt(walletAmountAsLovelace)
      ? walletAmountAsLovelace
          .div(rewardsPerEpochAsLovelace)
          .round(0, Big.roundDown)
          .toNumber()
      : remainingPremiumAsEpochs.toNumber();

  // console.log('PAYINTEREST')
  // if (bond !== null)
  // console.log(bond.premiumPaidAsLovelace.toString())
  // console.log(bond.rewardsPerEpochAsLovelace.toString())
  // console.log(bond.nowAsRelativeEpochs.toString())
  // console.log(bond.startAsRelativeEpochs.toString())
  // if (bond.currInterestBufferAsEpochs !== null) {
  //   console.log(bond.currInterestBufferAsEpochs.toString())
  // }
  // if (remainingPremiumAsEpochs !== null)
  //   console.log(remainingPremiumAsLovelace.toString())
  // console.log(bond.maxDurationAsEpochs.toString())

  const payInterest = (bond: OpenedBond2, epochCount: number) => async () => {
    const params = {
      openedBondAddress: bond.address,
      uniqNftPolicyId: bond.uniqCurrencySymbol,
      uniqNftTokenName: bond.uniqTokenName,
      marginAsEpochs: BigInt(epochCount),
    };
    if (epochCount <= 0) return;
    dispatch(addMargin(params));
    setIsSpinnerModalOpen(true);
  };

  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <h2 className="text-xl font-normal mb-6">Pay interest</h2>
        <div className="mb-5">
          <Text tone="muted">
            Bond Policy ID
            <Info label={bondTokenName} />
          </Text>
          <Text size="medium">{formatValue(utxoValueAsLovelace)}</Text>
        </div>
        <div className="py-2 flex flex-col gap-4">
          <div className="flex justify-between items-center">
            <Input
              className="w-[180px]"
              value={
                interestValueAsEpochs === 0 ? undefined : interestValueAsEpochs
              }
              onChange={(event) => {
                const value = Number(event.target.value);
                const restrictedValue =
                  value > maxSliderValue
                    ? maxSliderValue
                    : value < minSliderValue
                    ? minSliderValue
                    : value;
                setInterestValueAsEpochs(restrictedValue);
                // console.log('restrictedValue')
                // console.log(restrictedValue)
                // console.log(minSliderValue)
                // console.log(maxSliderValue)
                // console.log(value)
                if (bond.currInterestBufferAsEpochs !== null) {
                  setUpdatedInterest(
                    bond.currInterestBufferAsEpochs
                      .add(Big(restrictedValue))
                      .round(0, Big.roundDown)
                      .toNumber()
                  );
                }
              }}
              placeholder={maxSliderValue <= 0 ? "Not enough ₳" : "0"}
            />
            <div className="flex flex-col items-end">
              <Text tone="muted">Amount</Text>
              <Text className="text-[30px]">
                {formatValue(
                  Big(interestValueAsEpochs).mul(rewardsPerEpochAsLovelace)
                )}
              </Text>
            </div>
          </div>
          <Slider
            onChange={(value) => {
              const restrictedValue =
                value > maxSliderValue
                  ? maxSliderValue
                  : value < minSliderValue
                  ? minSliderValue
                  : value;
              setInterestValueAsEpochs(restrictedValue);
              if (bond.currInterestBufferAsEpochs !== null) {
                setUpdatedInterest(
                  bond.currInterestBufferAsEpochs
                    .add(Big(restrictedValue))
                    .round(0, Big.roundDown)
                    .toNumber()
                );
              }
            }}
            value={interestValueAsEpochs}
            min={minSliderValue}
            max={maxSliderValue}
            step={1}
          />
        </div>
        <div className="flex justify-between items-center mt-3 mb-5">
          <Text tone="muted">Available amount in wallet</Text>
          <Text>{formatValue(walletAmountAsLovelace)}</Text>
        </div>
        <div className="flex gap-5 mb-6">
          <div className="flex flex-col gap-4">
            <Text>
              Current Interest Buffer
              <Info position="top" label="Tooltip info" />
            </Text>
            <div className="flex justify-between items-center relative">
              <Input
                disabled
                value={
                  bond.currInterestBufferAsEpochs !== null
                    ? currentInterest
                    : "N/A"
                }
                onChange={(event) =>
                  setCurrentInterest(Number(event.target.value))
                }
                className="w-full rounded-3xl py-3"
              />
              <Text
                tone="muted"
                size="medium"
                className="absolute right-4 text-right"
              >
                Epoches
              </Text>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <Text>
              Updated Interest Buffer
              <Info position="top" label="Tooltip info" />
            </Text>
            <div className="flex justify-between items-center relative">
              <Input
                disabled
                value={
                  bond.currInterestBufferAsEpochs !== null
                    ? updatedInterest
                    : "N/A"
                }
                onChange={(event) =>
                  setUpdatedInterest(Number(event.target.value))
                }
                className="w-full rounded-3xl py-3 border-ui-base-green"
              />
              <Text
                size="medium"
                className="absolute right-4 text-right text-ui-base-green"
              >
                Epoches
              </Text>
            </div>
          </div>
        </div>
        <Button
          className="w-full"
          onClick={payInterest(bond, interestValueAsEpochs)}
        >
          Pay Interest
        </Button>
      </Modal>
      <SpinnerModal open={isSpinnerModalOpen} message={txSigningMessage} />
    </>
  );
};
