import { Network } from 'lucid-cardano'
const cardanoNetwork: Network = 'Preview'
const blockfrostEndpoint: string = 'https://preview-server.optim.finance/blockfrost'
const blockfrostKey: string = 'previewMtZHkzC9Pfwdb8l5uLF13HJyQPJtsLpy'
const optimServerUrl: string = 'https://preview-server.optim.finance'
const optimServer2Url: string = 'https://preview-server.optim.finance'
const wsUrl: string = 'wss://preview-server.optim.finance'
const verifiedNamesUrl: string = 'https://raw.githubusercontent.com/OptimFinance/verified-offers/main/preview-names'

export {
  cardanoNetwork,
  blockfrostEndpoint,
  blockfrostKey,
  optimServerUrl,
  optimServer2Url,
  wsUrl,
  verifiedNamesUrl
};
