import Big from "big.js";
import classNames from "classnames";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { formatValue } from "../../bond/getters/slice";
import { UITypes } from "../../types";
import { cn } from "../../utils/tailwind";
import { BondChart } from "../BondChart";
import styles from "../Card/index.module.scss";
import { Info } from "../Info";
import { Card } from "./card";
import { CustomIcon } from "./custom-icon";
import { Text } from "./typography";

interface Props extends UITypes.Card.CardData {
  children?: React.ReactNode;
  onDetailsButtonClick?: () => void;
}

const calculateCurrendLineWidth = (value: number) => {
  const result = Math.trunc(value * 100);
  return result > 100 ? 100 : result;
};

const copyToClipboard = (value: string) => {
  navigator.clipboard.writeText(value);
};

export const ProductCard: React.FC<Props> = ({
  children,
  header,
  details,
  lineColor,
  chartData,
  danger,
}: Props) => {
  header.iconType = header.iconType || "cardano";

  return (
    <Card
      data-testid="card"
      className={cn("grid gap-6", danger ? "border-ui-base-red" : "")}
    >
      <header className="flex items-center overflow-hidden">
        <CustomIcon icon="cardano" className="mr-2" />
        <div className="flex flex-col overflow-hidden flex-1">
          {header.left.name}
          {header.left.value}
        </div>
        <div className="ml-auto text-right">
          {header.right.name}
          {header.right.value}
        </div>
      </header>
      {header.type === "pool" ? (
        <div className={classNames(styles.colorLine)}>
          <div
            className={classNames(styles.colorLineCurrent, {
              [styles.violet]: lineColor,
            })}
            style={{
              width: `${calculateCurrendLineWidth(header.funded.toNumber())}%`,
            }}
          ></div>
        </div>
      ) : chartData ? (
        <BondChart {...chartData} />
      ) : (
        <div className={classNames(styles.separator)} />
      )}
      <ul className="space-y-3">
        {!!details?.length &&
          details.map((item) => (
            <li key={uuidv4()} className="flex items-center justify-between">
              <Text tone="muted">
                {item.name}
                {!!item?.tooltip && <Info label={item.tooltip} />}
              </Text>
              {item?.copyId ? (
                <Text onClick={() => copyToClipboard(item.value)}>Copy ID</Text>
              ) : (
                <Text>{item.value}</Text>
              )}
            </li>
          ))}
      </ul>
      {children && <footer>{children}</footer>}
    </Card>
  );
};

export const makePoolCardData = (
  poolName: string,
  fundedRatio: Big,
  fundedValue: Big,
  details: UITypes.Card.Detail[],
  lineColor?: "violet"
): UITypes.Card.CardData => {
  return {
    header: {
      type: "pool",
      left: {
        name: <Text tone="muted">OPool Name</Text>,
        value: (
          <Text size="medium" title={poolName} className="min-w-0 truncate">
            {poolName}
          </Text>
        ),
      },
      right: {
        name: (
          <Text tone="muted">
            {fundedRatio.mul(100).round(2, Big.roundDown).toString()}% Funded
          </Text>
        ),
        value: <Text size="medium">{formatValue(fundedValue)}</Text>,
      },
      funded: fundedRatio,
    },
    lineColor,
    details,
  };
};

export const makeBondCardData = (
  params: {
    bondName: string;
    rateLabel: string;
    adaRateFormatted: string;
    optRateFormatted?: string;
    icon?: UITypes.Card.IconType;
    chartData?: UITypes.Chart;
    danger?: boolean;
  },
  details: UITypes.Card.Detail[]
): UITypes.Card.CardData => {
  const {
    bondName,
    rateLabel,
    adaRateFormatted,
    optRateFormatted,
    icon,
    chartData,
    danger,
  } = params;
  return {
    header: {
      type: "interest",
      iconType: icon === undefined ? "cardano" : icon,
      left: {
        name: <Text tone="muted">Bond name</Text>,
        value: (
          <Text title={bondName} size="medium" className="max-w-[1/2] truncate">
            {bondName}
          </Text>
        ),
      },
      right: {
        name: <Text tone="muted">{rateLabel}</Text>,
        value: (
          <>
            <Text size="medium">{adaRateFormatted}</Text>
            {optRateFormatted !== undefined ? (
              <Text size="medium">{optRateFormatted}</Text>
            ) : (
              <></>
            )}
          </>
        ),
      },
    },
    chartData,
    details,
    danger,
  };
};

export const makeJboCardData = (
  params: {
    name: string;
  },
  details: UITypes.Card.Detail[]
): UITypes.Card.CardData => {
  const { name } = params;
  return {
    header: {
      type: "interest",
      iconType: "cardano",
      left: {
        name: <div className={styles.cardHeaderLabel}>Jbo name</div>,
        value: (
          <div title={name} className={styles.cardHeaderValue}>
            {name}
          </div>
        ),
      },
      right: {
        name: <></>,
        value: <></>,
      },
    },
    details,
  };
};
