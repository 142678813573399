import { cn } from "../../utils/tailwind";
import { Info } from "../Info";
import { Card } from "./card";
import { Input } from "./input";
import { Text } from "./typography";

export const IssueBondForm = ({ children }: { children: React.ReactNode }) => {
  return <div className="w-full grid gap-6">{children}</div>;
};

type IssueBondFormSectionProps = {
  children?: React.ReactNode;
  title: string;
  tooltip?: string;
};

export const IssueBondFormSection = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return <section className="grid py-3 gap-4">{children}</section>;
};

export const IssueBondFormSectionHeader = ({
  children,
  title,
  tooltip,
}: IssueBondFormSectionProps) => {
  return (
    <header className="flex justify-between items-center flex-nowrap">
      <Text>
        {title}
        {tooltip && <Info label={tooltip} />}
      </Text>
      {children}
    </header>
  );
};

export const RadioCardGroup = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex gap-4">{children}</div>;
};

export const RadioCard = ({
  title,
  description,
  onSelect,
  isSelected,
}: {
  title: string;
  description?: string;
  onSelect: React.MouseEventHandler<HTMLDivElement>;
  isSelected: boolean;
}) => {
  return (
    <Card
      className={cn(
        "flex flex-1 flex-col justify-center items-center cursor-pointer hover:border-ui-border-hover p-4",
        isSelected &&
          "bg-ui-base-white border-ui-base-white text-ui-surface-dark"
      )}
      onClick={onSelect}
    >
      <Text size="medium" weight="medium">
        {title}
      </Text>
      {description && (
        <Text
          tone="muted"
          className={cn(isSelected && " text-ui-surface-dark")}
        >
          {description}
        </Text>
      )}
    </Card>
  );
};
