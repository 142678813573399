import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal";
import cardano from "../../../assets/icons/big_cardano.svg";
import cardano2 from "../../../assets/icons/cardano.svg";
import diamond from "../../../assets/icons/diamond-512.svg";
import arrow from "../../../assets/icons/big_arrow-right.svg";
import styles from "./index.module.scss";
import { ReactSVG } from "react-svg";
import { ClosedBond2, WithClosedBondLender } from '../../../types';
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {redeemJboBondTokens, selectRedeemJboBondTokensResponse} from "../../../bond/actions";
import {jboTxResponseToAlert, resize} from "../../../bond/utils";
import {setAlert} from "../../../store/slices/alertSlice";
import {useEffect, useRef, useState} from "react"
import {formatAmount, formatValue, getBondPositions} from "../../../bond/getters/slice";
import {SpinnerModal} from "../../Modals/SpinnerModal";
import {updateWalletUtxosThunk} from "../../../store/slices/walletSlice";

interface Props {
  onClose?: () => void;
  isOpen: boolean,
  setIsOpen: (predicate: boolean) => void,
  bond:  WithClosedBondLender<ClosedBond2>,
  txSigningMessage?: string
}

export const RedeemModal = ({ isOpen, setIsOpen, bond, onClose, txSigningMessage }: Props) => {
  const dispatch = useAppDispatch()

  const [isSpinnerModalOpen, setIsSpinnerModalOpen] = useState(false);

  const redeemBondTokensResponse = useAppSelector(selectRedeemJboBondTokensResponse)

  const prev = useRef({ redeemBondTokensResponse })

  useEffect(() => {
    if (prev.current.redeemBondTokensResponse === redeemBondTokensResponse) {
      prev.current = { redeemBondTokensResponse }
    } else {
      dispatch(setAlert(jboTxResponseToAlert(redeemBondTokensResponse)))
      if (redeemBondTokensResponse !== undefined && redeemBondTokensResponse.tag === 'JboTxSuccess') {
        (async () => {
          await dispatch(updateWalletUtxosThunk(null))
          dispatch(getBondPositions({bondIds: [], bondFlag: 'BondFlagWritten'}))
          // dispatch(lookupBondHistoryThunk(bond.uniqTokenName))
          // dispatch(getUserBondHistoriesThunk())
        })()
        setIsOpen(false)
      }
      setIsSpinnerModalOpen(false)
    }
  }, [dispatch, bond.uniqTokenName, redeemBondTokensResponse])

  const buttonAction = () => {
    // const params = {
    //   input: bond.utxo
    // }
    // dispatch(redeemLiquidityBond(params))
    const params = {
      closedBondAddress: bond.address,
      uniqNftPolicyId: bond.uniqCurrencySymbol,
      uniqNftTokenName: bond.uniqTokenName,

      // duration: bond.maxDurationAsEpochs.toNumber(),
    }
    dispatch(redeemJboBondTokens(params))
    setIsSpinnerModalOpen(true)
  }

  return (
    <>
    <Modal
      open={isOpen}
      onClose={onClose}
      height={475}>
      <h3 className={styles.redeemTitle}>Redeem</h3>
      <div className={styles.icons}>
        <ReactSVG beforeInjection={resize(100, 100)} src={diamond} />
        <ReactSVG src={arrow} />
        <ReactSVG src={cardano} />
      </div>
      <Button size="xxl" fullWidth onClick={buttonAction}>Redeem</Button>
      <div className={styles.reward}>
        <div>
          <p className={styles.text}>You have</p>
          <p>{formatAmount(bond.user.walletBondTokenAmount)} Bond token</p>
        </div>
        <div className={styles.rightSide}>
          <div>
            <p className={styles.text}>You get</p>
            <p>{formatValue(bond.user.redemptionValueAsLovelace)}</p>
          </div>
          <ReactSVG src={cardano2} className={styles.cardanoIcon} />
        </div>
      </div>
    </Modal>
    <SpinnerModal open={isSpinnerModalOpen} message={txSigningMessage} />
    </>
  );
};
