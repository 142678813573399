import { FC, ReactNode, useState } from "react";
import { useParams } from "react-router-dom";
import {
  borrowerToLender,
  formatAsEpochs,
  formatLovelaceAsWords,
  getBondPosition,
  isActiveOpenedPoolIssuedBondPosition,
  makeAmountDetail,
  makeBorrowerInterestRateDetail,
  makeFutureAirdropDetails,
  makeInterestBufferDetail,
  makeInterestValueDetail,
  makeLenderInterestRateDetail,
  makeMaxDurationDetail,
  makePremiumPaidDetail,
  selectPosition,
} from "../../bond/getters/slice";
import { bondFaceValue } from "../../bond/getters/ui";
import { useQuery } from "../../bond/utils";
import { InfoPanel } from "../../components/InfoPanel";
import { PageHeader } from "../../components/PageHeader";
import { Spinner } from "../../components/Spinner";
import CustomTitle from "../../components/Title";
import { Container } from "../../components/ui/container";
import {
  useAppDispatch,
  useAppSelector,
  useLayoutInterval,
} from "../../store/hooks";
import { ConnectLedger } from "../../store/hooks/ledgerWallet";
import { BondPosition } from "../../types/ui";
import DetailsCard from "./components/DetailsCard";
import styles from "./index.module.scss";

const dataToInfoPanelProps = (
  position: BondPosition,
  radios: ReactNode,
  isBorrowerInterestRate: boolean
) => {
  const bond = position.bond;
  const maxDurationAsEpochs = bond.maxDurationAsEpochs;
  const amount = bond.totalBondTokenAmount;
  const interestBufferAsEpochs = bond.interestBufferAsEpochs;
  const premiumPaidAsEpochs =
    bond.tag === "OpenedBond"
      ? bond.currInterestBufferAsEpochs
      : bond.totalPremiumPaidAsEpochs;
  const interestRate = isBorrowerInterestRate
    ? bond.borrowerInterestRate
    : bond.lenderInterestRate;
  const interestValueAsLovelace = isBorrowerInterestRate
    ? bond.targetInterestAsLovelace
    : borrowerToLender(bond.optimFeeBasisPoints, bond.targetInterestAsLovelace);
  const airdrops =
    isBorrowerInterestRate || bond.verifiedName === null
      ? []
      : makeFutureAirdropDetails(bond.verifiedName);
  return {
    header: radios,
    duration:
      maxDurationAsEpochs === null ? "?" : formatAsEpochs(maxDurationAsEpochs),
    cost: formatLovelaceAsWords(amount.mul(bondFaceValue)),
    details: [
      makeAmountDetail(amount, "Amount"),
      isBorrowerInterestRate
        ? makeBorrowerInterestRateDetail(interestRate)
        : makeLenderInterestRateDetail(interestRate),
      makeMaxDurationDetail(maxDurationAsEpochs),
      ...airdrops,
      makeInterestBufferDetail(interestBufferAsEpochs),
      makeInterestValueDetail(interestValueAsLovelace),
      makePremiumPaidDetail(premiumPaidAsEpochs),
    ],
  };
};

interface Props {
  connectLedger: ConnectLedger;
}

const PoolDetails: FC<Props> = ({ connectLedger }) => {
  console.log("PoolDetails");
  const dispatch = useAppDispatch();
  const { poolTn } = useParams();
  const query = useQuery();
  const hasBorrowerQueryParam = query.get("borrower") !== null;

  const [isBorrowerInterestRate, setIsBorrowerInterestRate] = useState<boolean>(
    hasBorrowerQueryParam
  );

  useLayoutInterval(
    "PoolDetails",
    () => {
      dispatch(getBondPosition(poolTn));
    },
    60000
  );

  const position = useAppSelector(
    selectPosition(isActiveOpenedPoolIssuedBondPosition)
  );
  if (position === "Pending") return <Spinner />;
  if (position === null) return <></>;

  const radios = (
    <div className={styles.toggleRadioButtons}>
      <div className={styles.toggleRadioButton}>
        <input
          type="radio"
          id="borrower"
          name="irate"
          defaultChecked={isBorrowerInterestRate}
          onClick={() => setIsBorrowerInterestRate(true)}
        />
        <label htmlFor="borrower">Borrower</label>
      </div>
      <div className={styles.toggleRadioButton}>
        <input
          type="radio"
          id="lender"
          name="irate"
          defaultChecked={!isBorrowerInterestRate}
          onClick={() => setIsBorrowerInterestRate(false)}
        />
        <label htmlFor="lender">Lender</label>
      </div>
    </div>
  );

  const infoPanelProps = dataToInfoPanelProps(
    position,
    radios,
    isBorrowerInterestRate
  );

  return (
    <Container>
      <CustomTitle title="OPool Page" />
      <PageHeader
        breadcrumbs={[
          {
            path: "/bonds",
            crumbName: "Back to OPools",
          },
        ]}
        pageTitle="OPool page"
      />
      <div className="grid md:grid-cols-[1fr,440px] gap-10">
        <InfoPanel {...infoPanelProps} />
        <div className="grid gap-5 h-fit">
          <DetailsCard data={position} connectLedger={connectLedger} />
          {/*<BondExplorer />*/}
          {/*<BondHistory />*/}
        </div>
      </div>
    </Container>
  );
};

export default PoolDetails;
