import { FC, PropsWithChildren } from "react";
import { FiAlertCircle } from "react-icons/fi";
import { cn } from "../../utils/tailwind";

interface Props extends PropsWithChildren {
  className?: string;
  alert?: boolean;
}

export const Attention: FC<Props> = ({ children, className, alert }) => {
  return (
    <div
      className={cn(
        "flex gap-2 bg-[hsla(38,80%,67%,0.1)] text-sm p-4 rounded-xl text-ui-base-yellow",
        alert && "text-ui-error-light bg-[hsla(3,81%,58%,0.1)]"
      )}
    >
      <FiAlertCircle className="h-4 w-4 shrink-0" />

      {children}
    </div>
  );
};
