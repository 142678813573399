import { useState, useEffect, useRef } from 'react';
import styles from './index.module.scss';
import { Button } from '../../../components/Button';
import { Info } from '../../../components/Info';
import classNames from 'classnames';
import { Slider } from '../../../components/Slider';
import { ModalInput } from '../../BondPage/ModalInput';
import { Modal } from "../../../components/Modal";
import { OpenedBond2 } from '../../../types';
import Big from 'big.js';
import {useAppDispatch, useAppSelector} from '../../../store/hooks';
import {selectWalletLovelaceAmount, updateWalletUtxosThunk} from '../../../store/slices/walletSlice';
import {formatValue, getBondPositions } from '../../../bond/getters/slice';
import {addMargin, selectAddMarginJboBondResponse } from '../../../bond/actions';
import {bondFaceValueAsLovelace} from '../../../bond/getters/ui';
import {jboTxResponseToAlert} from '../../../bond/utils';
import {setAlert} from '../../../store/slices/alertSlice';
import { SpinnerModal } from '../../Modals/SpinnerModal';
// import {SpinnerModal} from '../../SpinnerModal';

interface Props {
  isOpen: boolean,
  setIsOpen: (predicate: boolean) => void,
  bond: OpenedBond2,
  onClose?: () => void;
  txSigningMessage?: string
}

export const PayInterestModal = ({ isOpen, setIsOpen, bond, onClose, txSigningMessage }: Props) => {
  console.log('PayInterestModal')
  const dispatch = useAppDispatch()
  // if (bond.currInterestBufferAsEpochs !== null) {
  //   console.log(bond)
  //   console.log(bond.currInterestBufferAsEpochs.toNumber())
  // }

  const [isSpinnerModalOpen, setIsSpinnerModalOpen] = useState(false);

  useEffect(() => {
    dispatch(updateWalletUtxosThunk(null))
  })


  const marginAddJboBondResponse = useAppSelector(selectAddMarginJboBondResponse)

  const prev = useRef({ marginAddResponse: marginAddJboBondResponse })

  const initialCurrentInterestBufferAsEpochs =
    bond.currInterestBufferAsEpochs === null
    ? 0
    : bond.currInterestBufferAsEpochs.round(0, Big.roundDown).toNumber()

  useEffect(() => {
    setCurrentInterest(initialCurrentInterestBufferAsEpochs)
    setUpdatedInterest(initialCurrentInterestBufferAsEpochs)
  }, [initialCurrentInterestBufferAsEpochs])

  useEffect(() => {
    if (prev.current.marginAddResponse === marginAddJboBondResponse) {
      prev.current = { marginAddResponse: marginAddJboBondResponse }
    } else {
      dispatch(setAlert(jboTxResponseToAlert(marginAddJboBondResponse)))
      if (marginAddJboBondResponse !== undefined && marginAddJboBondResponse.tag === 'JboTxSuccess') {
        (async () => {
          await dispatch(updateWalletUtxosThunk(null))
          dispatch(getBondPositions({bondIds: [], bondFlag: 'BondFlagWritten'}))
          // dispatch(lookupBondHistoryThunk(bond.uniqTokenName))
          // dispatch(getUserBondHistoriesThunk())
          setIsOpen(false)
        })()
        setUpdatedInterest(initialCurrentInterestBufferAsEpochs)
        setCurrentInterest(initialCurrentInterestBufferAsEpochs)
        setIsOpen(false)
      }
      setIsSpinnerModalOpen(false)
      setInterestValueAsEpochs(0)
    }
  }, [dispatch, marginAddJboBondResponse])

  const bondTokenName = bond.uniqTokenName

  const walletAmountAsLovelace = useAppSelector(selectWalletLovelaceAmount) 

  const minSliderValue = 0;
  const [interestValueAsEpochs, setInterestValueAsEpochs] = useState(0);
  const [currentInterest, setCurrentInterest] = useState(initialCurrentInterestBufferAsEpochs);
  const [updatedInterest, setUpdatedInterest] = useState(initialCurrentInterestBufferAsEpochs);
  // console.log(initialCurrentInterestBufferAsEpochs)
  // console.log(currentInterest)
  // console.log(updatedInterest)


  const rewardsPerEpochAsLovelace = bond.rewardsPerEpochAsLovelace
  const maxDurationAsEpochs = bond.maxDurationAsEpochs
  const premiumPaidAsLovelace = bond.totalPremiumPaidAsLovelace
  // TODO: remove the addition of 1 lovelace when new validation scripts are in
  const totalPremiumAsLovelace = rewardsPerEpochAsLovelace.mul(maxDurationAsEpochs).add(1)
  const remainingPremiumAsLovelaceRaw = totalPremiumAsLovelace.sub(premiumPaidAsLovelace)
  const remainingPremiumAsLovelace = remainingPremiumAsLovelaceRaw.lt(Big(0)) ? Big(0) : remainingPremiumAsLovelaceRaw
  const remainingPremiumAsEpochs = rewardsPerEpochAsLovelace.eq(Big(0))
    ? null
    : remainingPremiumAsLovelace
        .div(rewardsPerEpochAsLovelace)
        .round(0, Big.roundUp)
  const utxoValueAsLovelace = bond.totalPremiumPaidAsLovelace.add(bond.totalBondTokenAmount.mul(bondFaceValueAsLovelace))

  const maxSliderValue =
    remainingPremiumAsEpochs === null
    ? 0
    : remainingPremiumAsLovelace.gt(walletAmountAsLovelace)
      ? walletAmountAsLovelace.div(rewardsPerEpochAsLovelace).round(0, Big.roundDown).toNumber()
      : remainingPremiumAsEpochs.toNumber()

  // console.log('PAYINTEREST')
  // if (bond !== null)
  // console.log(bond.premiumPaidAsLovelace.toString())
  // console.log(bond.rewardsPerEpochAsLovelace.toString())
  // console.log(bond.nowAsRelativeEpochs.toString())
  // console.log(bond.startAsRelativeEpochs.toString())
  // if (bond.currInterestBufferAsEpochs !== null) {
  //   console.log(bond.currInterestBufferAsEpochs.toString())
  // }
  // if (remainingPremiumAsEpochs !== null)
  //   console.log(remainingPremiumAsLovelace.toString())
  // console.log(bond.maxDurationAsEpochs.toString())

  const payInterest = (bond: OpenedBond2, epochCount: number) => async () => {
    const params = {
      openedBondAddress: bond.address,
      uniqNftPolicyId: bond.uniqCurrencySymbol,
      uniqNftTokenName: bond.uniqTokenName,
      marginAsEpochs: BigInt(epochCount),
      // uniq
      // uniqTokenName: bond.uniqTokenName,
      // marginAsEpochs: epochCount,
      // duration: bond.maxDurationAsEpochs.toNumber(),
    }
    if (epochCount <= 0) return;
    dispatch(addMargin(params))
    setIsSpinnerModalOpen(true)
  }

  return (
    <>
    <Modal open={isOpen} onClose={onClose}>
      <h2 className={styles.title}>Pay interest</h2>
      <div>
        <div className={styles.fieldLabel}>
          Bond ID
          <Info label={bondTokenName} />
        </div>
        <span className={styles.fieldValue}>{formatValue(utxoValueAsLovelace)}</span>
      </div>
      <div className={styles.sliderWrapper}>
        <ModalInput
          inputId="inputAmount"
          value={interestValueAsEpochs === 0 ? null : interestValueAsEpochs}
          onValueChange={value => {
            const restrictedValue = value > maxSliderValue ? maxSliderValue : value < minSliderValue ? minSliderValue : value
            setInterestValueAsEpochs(restrictedValue)
            // console.log('restrictedValue')
            // console.log(restrictedValue)
            // console.log(minSliderValue)
            // console.log(maxSliderValue)
            // console.log(value)
            if (bond.currInterestBufferAsEpochs !== null) {
              setUpdatedInterest(
                bond.currInterestBufferAsEpochs
                  .add(Big(restrictedValue))
                  .round(0, Big.roundDown)
                  .toNumber()
              )
            }
          }}
          placeholder={maxSliderValue <= 0 ? "Not enough ₳" : "0"}
        />
        <div className={styles.amountValueWrapper}>
          <div className={classNames(styles.fieldLabel, styles.textRight)}>Amount</div>
          <span className={styles.amountValue}>{formatValue(Big(interestValueAsEpochs).mul(rewardsPerEpochAsLovelace))}</span>
        </div>
        <Slider
          onChange={(value) => { 
            const restrictedValue = value > maxSliderValue ? maxSliderValue : value < minSliderValue ? minSliderValue : value
            setInterestValueAsEpochs(restrictedValue) 
            if (bond.currInterestBufferAsEpochs !== null) {
              setUpdatedInterest(
                bond.currInterestBufferAsEpochs
                  .add(Big(restrictedValue))
                  .round(0, Big.roundDown)
                  .toNumber()
              )
            }
          }}
          value={interestValueAsEpochs}
          min={minSliderValue}
          max={maxSliderValue}
          step={1}
        />
      </div>
      <div className={styles.availableLabelWrapper}>
        <div className={styles.fieldLabel}>Available amount in wallet</div>
        <span className={classNames(styles.fieldValue, styles.small)}>{formatValue(walletAmountAsLovelace)}</span>
      </div>
      <div className={styles.interestFieldsWrapper}>
        <div className={styles.interestField}>
          <div className={styles.interestLabel}>
            Current Interest Buffer
            <Info
              position="top"
              label="Tooltip info"
            />
          </div>
          <ModalInput
            inputId="currentInterest"
            isDisabled
            value={bond.currInterestBufferAsEpochs !== null ? currentInterest : 'N/A'}
            note="Epochs"
            isFullWidth={true}               
            onValueChange={setCurrentInterest}
          />
        </div>
        <div className={styles.interestField}>
          <div className={styles.interestLabel}>
            Updated Interest Buffer
            <Info
              position="top"
              label="Tooltip info"
            />
          </div>
          <ModalInput
            inputId="updatedInterest"
            isDisabled
            value={bond.currInterestBufferAsEpochs !== null ? updatedInterest : 'N/A'}
            note="Epochs"
            isGreen={true}            
            isFullWidth={true}            
            onValueChange={setUpdatedInterest}
          />
        </div>
      </div>
      <Button
        size="xxl"
        fullWidth={true}
        onClick={payInterest(bond, interestValueAsEpochs)}
      >
        Pay Interest
      </Button>
    </Modal>
    <SpinnerModal open={isSpinnerModalOpen} message={txSigningMessage} />
    </>
  )
}
