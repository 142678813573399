import { ReactNode, useState } from "react";
import { useParams } from "react-router-dom";
import {
  borrowerToLender,
  formatAsEpochs,
  formatLovelaceAsWords,
  getBondPosition,
  isBondPosition,
  makeAmountDetail,
  makeBorrowerInterestRateDetail,
  makeFutureAirdropDetails,
  makeInterestBufferDetail,
  makeInterestValueDetail,
  makeLenderInterestRateDetail,
  makeMaxDurationDetail,
  makePremiumPaidDetail,
  selectPosition,
} from "../../bond/getters/slice";
import { bondFaceValueAsLovelace } from "../../bond/getters/ui";
import { useQuery } from "../../bond/utils";
import { InfoPanel } from "../../components/InfoPanel";
import { PageHeader } from "../../components/PageHeader";
import { Spinner } from "../../components/Spinner";
import CustomTitle from "../../components/Title";
import { Container } from "../../components/ui/container";
import {
  useAppDispatch,
  useAppSelector,
  useLayoutInterval,
} from "../../store/hooks";
import { BondPosition } from "../../types/ui";
import { BondExplorer } from "./BondExplorer";
import { BondHealth } from "./BondHealth";
import { BondHistory } from "./BondHistory";
import styles from "./index.module.scss";

const dataToInfoPanelProps = (
  position: BondPosition,
  radios: ReactNode,
  isBorrowerInterestRate: boolean
) => {
  const bond = position.bond;
  const maxDurationAsEpochs = bond.maxDurationAsEpochs;
  const amount = bond.totalBondTokenAmount;
  const interestBufferAsEpochs = bond.interestBufferAsEpochs;
  const premiumPaidAsEpochs =
    bond.tag === "OpenedBond"
      ? bond.currInterestBufferAsEpochs
      : bond.totalPremiumPaidAsEpochs;
  const interestRate = isBorrowerInterestRate
    ? bond.borrowerInterestRate
    : bond.lenderInterestRate;
  const interestValueAsLovelace = isBorrowerInterestRate
    ? bond.targetInterestAsLovelace
    : borrowerToLender(bond.optimFeeBasisPoints, bond.targetInterestAsLovelace);
  const airdrops =
    isBorrowerInterestRate || bond.verifiedName === null
      ? []
      : makeFutureAirdropDetails(bond.verifiedName);
  return {
    header: radios,
    duration:
      maxDurationAsEpochs === null ? "?" : formatAsEpochs(maxDurationAsEpochs),
    cost: formatLovelaceAsWords(amount.mul(bondFaceValueAsLovelace)),
    details: [
      makeAmountDetail(amount, "Amount"),
      isBorrowerInterestRate
        ? makeBorrowerInterestRateDetail(interestRate)
        : makeLenderInterestRateDetail(interestRate),
      makeMaxDurationDetail(maxDurationAsEpochs),
      ...airdrops,
      makeInterestBufferDetail(interestBufferAsEpochs),
      makeInterestValueDetail(interestValueAsLovelace),
      makePremiumPaidDetail(premiumPaidAsEpochs),
    ],
  };
};

export const BondPage = (props: { txSigningMessage?: string }) => {
  console.log("BondPage");
  const dispatch = useAppDispatch();
  const { bondTn } = useParams();
  const query = useQuery();
  const isBorrower = query.get("borrower") !== null;

  const [isBorrowerInterestRate, setIsBorrowerInterestRate] =
    useState<boolean>(isBorrower);

  useLayoutInterval(
    "BondPage",
    () => {
      dispatch(getBondPosition(bondTn));
    },
    60000
  );

  const position = useAppSelector(selectPosition(isBondPosition));
  if (position === "Pending") return <Spinner />;
  if (position === null) return <></>;

  const params = {
    position,
    txSigningMessage: props.txSigningMessage,
  };

  const radios = (
    <div className={styles.toggleRadioButtons}>
      <div className={styles.toggleRadioButton}>
        <input
          type="radio"
          id="borrower"
          name="irate"
          defaultChecked={isBorrowerInterestRate}
          onClick={() => setIsBorrowerInterestRate(true)}
        />
        <label htmlFor="borrower">Borrower</label>
      </div>
      <div className={styles.toggleRadioButton}>
        <input
          type="radio"
          id="lender"
          name="irate"
          defaultChecked={!isBorrowerInterestRate}
          onClick={() => setIsBorrowerInterestRate(false)}
        />
        <label htmlFor="lender">Lender</label>
      </div>
    </div>
  );

  const infoPanelProps = dataToInfoPanelProps(
    position,
    radios,
    isBorrowerInterestRate
  );

  return (
    <Container>
      <CustomTitle title="Bond" />
      <PageHeader
        breadcrumbs={[
          {
            path: "/bonds",
            crumbName: "Back to Bonds",
          },
        ]}
        pageTitle="Bond page"
      />
      <div className="grid md:grid-cols-[1fr,440px] gap-10">
        <InfoPanel {...infoPanelProps} />
        <div className="grid gap-5">
          <BondHealth {...params} />
          {position.bond.stakeAddress !== null ? (
            <BondExplorer stakeAddress={position.bond.stakeAddress} />
          ) : (
            <></>
          )}
          <BondHistory />
        </div>
      </div>
    </Container>
  );
};
