import React, { useEffect, useRef, useState } from "react";
import { cancelBond, selectCancelJboBondResponse } from "../../../bond/actions";
import {
  formatValue,
  getBondPosition,
  getUserBondPositions,
} from "../../../bond/getters/slice";
import { jboTxResponseToAlert } from "../../../bond/utils";
import { Attention } from "../../../components/Attention";
import { Modal } from "../../../components/Modal";
import { Button } from "../../../components/ui/button";
import { Card } from "../../../components/ui/card";
import { Text } from "../../../components/ui/typography";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setAlert } from "../../../store/slices/alertSlice";
import { IssuedBond2 } from "../../../types";
import { SpinnerModal } from "../SpinnerModal";

interface Props {
  onClose?: () => void;
  isOpen: boolean;
  setIsOpen: (predicate: boolean) => void;
  bond: IssuedBond2;
  txSigningMessage?: string;
}

export const CancelModal: React.FC<Props> = ({
  isOpen,
  setIsOpen,
  bond,
  onClose,
  txSigningMessage,
}) => {
  const dispatch = useAppDispatch();

  const [isSpinnerModalOpen, setIsSpinnerModalOpen] = useState(false);

  const cancelBondResponse = useAppSelector(selectCancelJboBondResponse);

  const prev = useRef({ cancelBondResponse });

  useEffect(() => {
    if (prev.current.cancelBondResponse === cancelBondResponse) {
      prev.current = { cancelBondResponse };
    } else {
      dispatch(setAlert(jboTxResponseToAlert(cancelBondResponse)));
      if (
        cancelBondResponse !== undefined &&
        cancelBondResponse.tag === "JboTxSuccess"
      ) {
        dispatch(getBondPosition(bond.uniqTokenName));
        dispatch(getUserBondPositions());
        setIsOpen(false);
      }
      setIsSpinnerModalOpen(false);
    }
  }, [dispatch, cancelBondResponse]);

  const buttonAction = () => {
    const params = {
      bondWriterAddress: bond.address,
      uniqNftPolicyId: bond.uniqCurrencySymbol,
      uniqNftTokenName: bond.uniqTokenName,
    };
    dispatch(cancelBond(params));
    setIsSpinnerModalOpen(true);
  };
  return (
    <>
      <Modal open={isOpen} onClose={onClose}>
        <h3 className="text-2xl">Cancel</h3>
        <Attention>
          Cancelling allows for the redemption of all deposited interest
          Premium. The bond has not activated and no interest has been paid.
        </Attention>
        <Card className="flex items-center justify-between my-6">
          <Text tone="muted">Total ADA</Text>
          <Text>{formatValue(bond.totalPremiumPaidAsLovelace)}</Text>
        </Card>
        <Button
          className="w-full bg-ui-error-default hover:bg-ui-error-light"
          onClick={buttonAction}
        >
          Cancel
        </Button>
      </Modal>
      <SpinnerModal open={isSpinnerModalOpen} message={txSigningMessage} />
    </>
  );
};
