import { useRef, useState, useEffect } from "react";
import { Button } from "../../../components/Button";
import styles from "./index.module.scss";
import { OpenedBond2 } from '../../../types';
import { Modal } from "../../../components/Modal";
import { InputBox } from "../../../components/InputBox";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {changeStakeKeyBond, selectChangeStakeKeyJboBondResponse } from "../../../bond/actions";
import {setAlert} from "../../../store/slices/alertSlice";
import {jboTxResponseToAlert} from "../../../bond/utils";
import {SpinnerModal} from "../../Modals/SpinnerModal";
import {getBondPositions} from "../../../bond/getters/slice";

interface Props {
  // data: UITypes.Modals.ChangeKey
  onClose?: () => void;
  isOpen: boolean,
  setIsOpen: (predicate: boolean) => void,
  bond: OpenedBond2,
  txSigningMessage?: string
}

export const ChangeKeyModal = ({ isOpen, setIsOpen, bond, onClose, txSigningMessage }: Props) => {
  const dispatch = useAppDispatch()

  const [isSpinnerModalOpen, setIsSpinnerModalOpen] = useState(false);

  const changeStakeKeyJboBondResponse = useAppSelector(selectChangeStakeKeyJboBondResponse)

  const prev = useRef({ changeKeyResponse: changeStakeKeyJboBondResponse })

  useEffect(() => {
    if (prev.current.changeKeyResponse === changeStakeKeyJboBondResponse) {
      prev.current = { changeKeyResponse: changeStakeKeyJboBondResponse }
    } else {
      dispatch(setAlert(jboTxResponseToAlert(changeStakeKeyJboBondResponse)))
      if (changeStakeKeyJboBondResponse !== undefined) {
        if (changeStakeKeyJboBondResponse.tag === 'JboTxSuccess') {
          dispatch(getBondPositions({bondIds: [], bondFlag: 'BondFlagWritten'}))
          // dispatch(lookupBondHistoryThunk(bond.uniqTokenName))
          // dispatch(getUserBondHistoriesThunk())
          setIsOpen(false)
        }
      }
      setIsSpinnerModalOpen(false)
      setKeyHash('')
    }
  }, [dispatch, changeStakeKeyJboBondResponse])

  const [keyHash, setKeyHash] = useState("");

  const changeKeyButtonClick = () => {
    if (keyHash === '') return;

    const params = {
      openedBondAddress: bond.address,
      uniqNftPolicyId: bond.uniqCurrencySymbol,
      uniqNftTokenName: bond.uniqTokenName,
      stakeAddress: keyHash,
    }
    dispatch(changeStakeKeyBond(params))
    setIsSpinnerModalOpen(true)
  }

  return (
    <>
    <Modal className={styles.wrapper} open={isOpen} onClose={onClose}>
      <div>
        <p className={styles.title}>Change stake key</p>
        <p className={styles.info}>
          Changing the stake key associated with a bond will direct all delegation and staking rewards to the the stake pool and rewards account associated with the new stake key address
        </p>
        <div className={styles.amountInfo}>
          <InputBox
            value={keyHash}
            onChange={e => {setKeyHash(e.currentTarget.value)}}
          />
        </div>
        <div className={styles.btnWrapper}>
          <Button
            className={styles.button}
            size="xxl"
            fullWidth
            onClick={changeKeyButtonClick}
          >
            Change Stake Key
          </Button>
        </div>
      </div>
    </Modal>
    <SpinnerModal open={isSpinnerModalOpen} message={txSigningMessage} />
    </>
  );
};
