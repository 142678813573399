import Big from "big.js";
import { useEffect, useRef, useState } from "react";
import { FiArrowDown, FiArrowUpRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { matchBond, selectMatchBondResponse } from "../../../bond/actions";
import {
  getUserBondPositions,
  isActiveOpenedPoolLenderIssuedBondPosition,
  isOpenedPoolLenderCancelledBondPosition,
  makeFutureAirdropDetails,
  makeInterestBufferDetail,
  makeLenderInterestRateDetail,
  makeMaxDurationDetail,
  makePremiumPaidDetail,
  makeYourAmountDetail,
  makeYourValueDetail,
  selectPositions,
} from "../../../bond/getters/slice";
import { jboTxResponseToAlert } from "../../../bond/utils";
import { makePoolCardData } from "../../../components/Card";
import { ProductCard } from "../../../components/ui/ProductCard";
import { ProductCardsWrapper } from "../../../components/ui/ProductCardsWrapper";
import { Button } from "../../../components/ui/button";
import { network } from "../../../network";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setAlert } from "../../../store/slices/alertSlice";
import { setModal, unsetModal } from "../../../store/slices/modalsSlice";
import { updateWalletUtxosThunk } from "../../../store/slices/walletSlice";
import {
  OpenedPoolCancelledBondPosition,
  OpenedPoolIssuedBondPosition,
  UITypes,
} from "../../../types";
import { SpinnerModal } from "../../Modals/SpinnerModal";

const openedPoolDataToActivePoolCardData = (
  data: OpenedPoolIssuedBondPosition
): UITypes.Card.CardData => {
  const bond = data.bond;
  const pool = data.pool;
  const walletPoolTokenAmount =
    pool.user.tag === "Lender" ? pool.user.walletPoolTokenAmount : Big(0);
  const poolName = pool.poolName;

  const lineColor = pool.fundedRatio.lt(Big(1)) ? "violet" : undefined;
  const airdropDetails = makeFutureAirdropDetails(bond.verifiedName);
  return makePoolCardData(
    poolName,
    pool.fundedRatio,
    pool.poolTokenBoughtAmountAsLovelace,
    [
      makeYourAmountDetail(walletPoolTokenAmount, bond.totalBondTokenAmount),
      makeYourValueDetail(
        pool.poolTokenBoughtAmountAsLovelace,
        bond.totalBondTokenAmountAsLovelace
      ),
      makeLenderInterestRateDetail(bond.lenderInterestRate),
      ...airdropDetails,
      makeMaxDurationDetail(bond.maxDurationAsEpochs),
      makePremiumPaidDetail(bond.totalPremiumPaidAsEpochs),
      makeInterestBufferDetail(bond.interestBufferAsEpochs),
    ],
    lineColor
  );
};

const openedPoolDataToMissingIssuedBondCardData = (
  data: OpenedPoolCancelledBondPosition
): UITypes.Card.CardData => {
  const pool = data.pool;
  const poolName = pool.poolName;

  const lineColor = pool.fundedRatio.lt(Big(1)) ? "violet" : undefined;
  return makePoolCardData(
    poolName,
    pool.fundedRatio,
    pool.poolTokenBoughtAmountAsLovelace,
    [
      {
        name: "The corresponding bond has been cancelled.",
        value: "",
      },
      {
        name: "Please withdraw your funds.",
        value: "",
      },
    ],
    lineColor
  );
};

export const YourPools = ({
  txSigningMessage,
}: {
  txSigningMessage?: string;
}) => {
  console.log("YourPools");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const matchBondResponse = useAppSelector(selectMatchBondResponse);

  const prev = useRef({ matchBondResponse });

  const [isSpinnerModalOpen, setIsSpinnerModalOpen] = useState(false);

  useEffect(() => {
    if (prev.current.matchBondResponse === matchBondResponse) {
      prev.current = { matchBondResponse };
    } else {
      dispatch(setAlert(jboTxResponseToAlert(matchBondResponse)));
      if (
        matchBondResponse !== undefined &&
        matchBondResponse.tag === "JboTxSuccess"
      ) {
        (async () => {
          await dispatch(updateWalletUtxosThunk(null));
          // dispatch(getOpenPoolsThunk())
          dispatch(getUserBondPositions());
          dispatch(unsetModal());
        })();
      }
      setIsSpinnerModalOpen(false);
    }
  }, [dispatch, matchBondResponse]);

  const pendingPoolsWithBond = useAppSelector(
    selectPositions(
      "BondFlagWritten",
      "User",
      isActiveOpenedPoolLenderIssuedBondPosition
    )
  );
  const pendingPoolsWithoutBond = useAppSelector(
    selectPositions(
      "BondFlagWritten",
      "User",
      isOpenedPoolLenderCancelledBondPosition
    )
  );

  const pendingPoolWithBondCards = pendingPoolsWithBond.map((position) => {
    const pool = position.pool;
    let cardData = openedPoolDataToActivePoolCardData(position);
    const url = `/pools/${pool.poolTokenName}`;
    return {
      cardData,
      url,
      poolCurrencySymbol: pool.poolCurrencySymbol,
      poolTokenName: pool.poolTokenName,
      data: position,
    };
  });

  const pendingPoolWithoutBondCards = pendingPoolsWithoutBond.map((data) => {
    const pool = data.pool;
    let cardData = openedPoolDataToMissingIssuedBondCardData(data);
    const url = `/pools/${pool.poolTokenName}`;
    return {
      cardData,
      url,
      poolCurrencySymbol: pool.poolCurrencySymbol,
      poolTokenName: pool.poolTokenName,
      data,
    };
  });

  const openDepositeModal = (position: OpenedPoolIssuedBondPosition) => () => {
    const pool = position.pool;
    const bond = position.bond;
    const bondWriterUtxoRef = {
      txHash: bond.utxoRef.txId,
      outputIndex: bond.utxoRef.txIx,
    };
    dispatch(
      setModal({
        type: "deposit",
        data: {
          poolCurrencySymbol: pool.poolCurrencySymbol,
          poolTokenName: pool.poolTokenName,
          poolSize: pool.poolSize.toNumber(),
          poolTokenCount: pool.poolSize
            .sub(pool.poolTokenBoughtAmount)
            .toNumber(),
          bondWriterUtxoRef,
          duration: bond.maxDurationAsEpochs.toNumber(),
          optimFeeBasisPoints: bond.optimFeeBasisPoints.toNumber(),
          defStk: pool.stakeKeyHash ?? network.currentDefStk,
        },
      })
    );
  };

  const openWithdrawModal =
    (
      position: OpenedPoolIssuedBondPosition | OpenedPoolCancelledBondPosition
    ) =>
    () => {
      const pool = position.pool;
      dispatch(
        setModal({
          type: "withdraw",
          data: {
            poolCurrencySymbol: pool.poolCurrencySymbol,
            poolTokenName: pool.poolTokenName,
            poolTokenBoughtAmount: pool.poolTokenBoughtAmount.toNumber(),
            poolSize: pool.poolSize.toNumber(),
            duration: position.bond.maxDurationAsEpochs.toNumber(),
            optimFeeBasisPoints: position.bond.optimFeeBasisPoints.toNumber(),
            defStk: pool.stakeKeyHash ?? network.currentDefStk,
          },
        })
      );
    };

  const matchBondAction = (position: OpenedPoolIssuedBondPosition) => () => {
    const issueBondUtxo = position.bond.utxoRef;
    const params = {
      poolTokenName: position.pool.poolTokenName,
      poolSize: position.pool.poolSize.toNumber(),
      purchaseAmount: 0,
      bondWriterUtxoRef: {
        txHash: issueBondUtxo.txId,
        outputIndex: issueBondUtxo.txIx,
      },
      duration: position.bond.maxDurationAsEpochs.toNumber(),
      optimFeeBasisPoints: position.bond.optimFeeBasisPoints.toNumber(),
      defStk: position.pool.stakeKeyHash ?? network.currentDefStk,
    };
    dispatch(matchBond(params));
    setIsSpinnerModalOpen(true);
  };

  return (
    <>
      <ProductCardsWrapper>
        {!!pendingPoolWithoutBondCards &&
          pendingPoolWithoutBondCards?.map((item) => (
            <ProductCard {...item.cardData} key={uuidv4()}>
              <Button
                variant="secondary"
                onClick={openWithdrawModal(item.data)}
              >
                Withdraw
                <FiArrowUpRight className="h-5 w-5" />
              </Button>
            </ProductCard>
          ))}
        {!!pendingPoolWithBondCards &&
          pendingPoolWithBondCards?.map((item) => (
            <ProductCard
              {...item.cardData}
              key={uuidv4()}
              onDetailsButtonClick={() => navigate(item.url)}
            >
              <Button
                variant="secondary"
                onClick={openWithdrawModal(item.data)}
              >
                Withdraw
                <FiArrowUpRight className="h-5 w-5" />
              </Button>
              {!item.data.pool.fundedRatio.gte(Big(1)) ? (
                <Button variant="white" onClick={openDepositeModal(item.data)}>
                  Deposit
                  <FiArrowDown className="h-5 w-5" />
                </Button>
              ) : (
                <Button onClick={matchBondAction(item.data)}>
                  Execute
                  <FiArrowDown className="h-5 w-5" />
                </Button>
              )}
            </ProductCard>
          ))}
      </ProductCardsWrapper>
      <SpinnerModal open={isSpinnerModalOpen} message={txSigningMessage} />
    </>
  );
};
