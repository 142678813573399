import { Input } from "../../../components/ui/input";
import styles from "./index.module.scss";
import classNames from "classnames";

interface Props {
  inputId: string;
  // hacky. don't know how to make number input not always have 0
  value: number | string | null;
  placeholder?: string;
  note?: string;
  isGreen?: boolean;
  isFullWidth?: boolean;
  isDisabled?: boolean;
  onValueChange: (value: number) => void;
}

// TODO: fix this to use string like it did originally.
// and then have some nutty onChange to validate input
// like issue-bond does.
export const ModalInput = ({
  inputId,
  value,
  placeholder,
  note,
  isGreen,
  isFullWidth,
  onValueChange,
  isDisabled,
}: Props) => {
  return (
    <label
      htmlFor={inputId}
      className={classNames(
        styles.inputWrapper,
        { [styles.green]: isGreen },
        { [styles.fullWidth]: isFullWidth }
      )}
    >
      <Input
        type={typeof value === "string" ? "string" : "number"}
        disabled={isDisabled ? true : false}
        placeholder={placeholder}
        id={inputId}
        value={value === null ? "" : value}
        onChange={(event) => onValueChange(Number(event.target.value))}
      />
      {note && <span className={styles.inputNote}>{note}</span>}
    </label>
  );
};
