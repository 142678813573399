import styles from "./index.module.scss";
import classNames from "classnames";
import { ReactSVG } from "react-svg";
import iconWand from "../../../assets/icons/li_wand.svg";
import { v4 as uuidv4 } from "uuid";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setModal } from "../../../store/slices/modalsSlice";
import {
  selectPositions,
  isClosedPoolPosition,
  isClosedBondLenderPosition,
  isOpenedBondLenderPosition,
  isClosable2,
  lenderInterestRateLabel,
  formatRateAsPercentAda,
  makeFutureAirdropDetails,
  makeAmountDetail,
  makeMaxDurationDetail,
  makeInterestBufferDetail,
  makeAccruedInterestDetail,
  makePremiumPaidDetail,
  makeBondTokenIdDetail,
  makeAdaValueDetail,
  makeInterestValueDetail,
  makeTotalRedemptionValueDetail,
} from "../../../bond/getters/slice";
import { selectWalletPoolTokenNames } from "../../../store/slices/walletSlice";
import { useNavigate } from "react-router-dom";
import {
  ClosedBond2,
  ClosedBondLenderPosition,
  ClosedPool2,
  ClosedPoolPosition,
  OpenedBond2,
  OpenedBondLenderPosition,
  UITypes,
} from "../../../types";
import Big from "big.js";
import { useState } from "react";
import { CloseModal } from "../../Modals/CloseModal";
import { RedeemModal } from "../../Modals/RedeemModal";
import { network } from "../../../network";
import { ProductCardsWrapper } from "../../../components/ui/ProductCardsWrapper";
import {
  ProductCard,
  makeBondCardData,
} from "../../../components/ui/ProductCard";
import { Button } from "../../../components/ui/button";
import { Text } from "../../../components/ui/typography";

const openedBondClosedPoolDataToConvertableCardData = (
  data: ClosedPoolPosition
): UITypes.Card.CardData => {
  const bond = data.bond;
  const bondName = bond.bondName;
  const amount =
    bond.user.tag === "Both" || bond.user.tag === "Lender"
      ? bond.user.walletPoolTokenAmount
      : Big(0);
  return makeBondCardData(
    {
      icon: "sphere",
      bondName,
      rateLabel: lenderInterestRateLabel,
      adaRateFormatted: formatRateAsPercentAda(bond.lenderInterestRate),
    },
    [
      makeAmountDetail(amount, "Your Amount", "Amount of EQT owned"),
      makeMaxDurationDetail(bond.maxDurationAsEpochs),
      makeInterestBufferDetail(bond.interestBufferAsEpochs),
      ...makeFutureAirdropDetails(bond.verifiedName),
    ]
  );
};

const openedBondDataToActiveBondCardData = (
  position: OpenedBondLenderPosition
): UITypes.Card.CardData => {
  const bond = position.bond;
  const walletBondTokenAmount = bond.user.walletBondTokenAmount;
  const chartData = {
    relFirstEpoch: Number(bond.startAsRelativeEpochs),
    relCurrEpoch: Number(bond.nowAsRelativeEpochs),
    relLastEpoch: Number(
      bond.startAsRelativeEpochs.add(bond.maxDurationAsEpochs)
    ),
    currBufferAsEpochs:
      bond.currInterestBufferAsEpochs !== null
        ? Number(bond.currInterestBufferAsEpochs)
        : null,
    bufferAsEpochs: Number(bond.interestBufferAsEpochs),
  };
  const bondName = bond.bondName;
  return makeBondCardData(
    {
      icon: "diamond",
      bondName,
      rateLabel: lenderInterestRateLabel,
      adaRateFormatted: formatRateAsPercentAda(bond.lenderInterestRate),
      chartData,
    },
    [
      makeAccruedInterestDetail(
        bond.user.accruedInterestAsLovelace.minus(bond.user.optimFeeAsLovelace)
      ),
      makeAmountDetail(
        walletBondTokenAmount,
        "Your Amount",
        "Amount of BT owned (convert EQT to BT)"
      ),
      makeMaxDurationDetail(bond.maxDurationAsEpochs),
      makeInterestBufferDetail(bond.interestBufferAsEpochs),
      makePremiumPaidDetail(bond.currInterestBufferAsEpochs),
      makeBondTokenIdDetail(bond.uniqTokenName),
      ...makeFutureAirdropDetails(bond.verifiedName),
    ]
  );
};

const openedBondDataToClosableBondCardData = (
  position: OpenedBondLenderPosition
): UITypes.Card.CardData => {
  const bond = position.bond;
  const walletBondTokenAmount = bond.user.walletBondTokenAmount;
  const chartData = {
    relFirstEpoch: Number(bond.startAsRelativeEpochs),
    relCurrEpoch: Number(bond.nowAsRelativeEpochs),
    relLastEpoch: Number(
      bond.startAsRelativeEpochs.add(bond.maxDurationAsEpochs)
    ),
    currBufferAsEpochs:
      bond.currInterestBufferAsEpochs !== null
        ? Number(bond.currInterestBufferAsEpochs)
        : null,
    bufferAsEpochs: Number(bond.interestBufferAsEpochs),
  };
  const bondName = bond.bondName;
  return makeBondCardData(
    {
      icon: "diamond",
      bondName,
      rateLabel: lenderInterestRateLabel,
      adaRateFormatted: formatRateAsPercentAda(bond.lenderInterestRate),
      chartData,
    },
    [
      makeAmountDetail(
        walletBondTokenAmount,
        "Your Amount",
        "Amount of BT owned (convert EQT to BT)"
      ),
      makeMaxDurationDetail(bond.maxDurationAsEpochs),
      makeInterestBufferDetail(bond.interestBufferAsEpochs),
      makePremiumPaidDetail(bond.currInterestBufferAsEpochs),
      ...makeFutureAirdropDetails(bond.verifiedName),
    ]
  );
};

const closedBondDataToClosedPositionCardData = (
  position: ClosedBondLenderPosition
): UITypes.Card.CardData => {
  const bond = position.bond;
  const bondName = bond.bondName;
  return makeBondCardData(
    {
      icon: "cardano",
      bondName,
      rateLabel: lenderInterestRateLabel,
      adaRateFormatted: formatRateAsPercentAda(bond.lenderInterestRate),
    },
    [
      makeAmountDetail(
        bond.user.walletBondTokenAmount,
        "Your Bond Amount",
        "Amount of BT owned"
      ),
      makeAdaValueDetail(bond.user.walletBondTokenAmountAsLovelace),
      makeInterestValueDetail(bond.user.interestValueAsLovelace),
      makeTotalRedemptionValueDetail(bond.user.redemptionValueAsLovelace),
      ...makeFutureAirdropDetails(bond.verifiedName),
    ]
  );
};

export const OpenedPositions = ({
  txSigningMessage,
}: {
  txSigningMessage?: string;
}) => {
  console.log("OpenedPositions");
  const dispatch = useAppDispatch();

  const [closeModalIndex, setCloseModalIndex] = useState(-1);
  const [redeemModalIndex, setRedeemModalIndex] = useState(-1);

  const navigate = useNavigate();

  const walletPoolTokenNames = useAppSelector(selectWalletPoolTokenNames);
  const walletPoolTokenNameSet = new Set(walletPoolTokenNames);

  const convertablePositions = useAppSelector(
    selectPositions("BondFlagWritten", "User", isClosedPoolPosition)
  );
  const closedBondLenderPositions = useAppSelector(
    selectPositions("BondFlagWritten", "User", isClosedBondLenderPosition)
  );
  const openedBondLenderPositions = useAppSelector(
    selectPositions("BondFlagWritten", "User", isOpenedBondLenderPosition)
  );

  const convertableCards = convertablePositions
    .filter((position) =>
      walletPoolTokenNameSet.has(position.pool.poolTokenName)
    )
    .map((data) => {
      const pool = data.pool;
      const cardData = openedBondClosedPoolDataToConvertableCardData(data);
      const url = `/bonds/${data.bond.uniqTokenName}`;
      return { cardData, url, pool, bond: data.bond };
    });

  const unclosablePositions = [];
  const closablePositions = [];
  for (const bond of openedBondLenderPositions) {
    if (isClosable2(bond.bond)) closablePositions.push(bond);
    else unclosablePositions.push(bond);
  }

  const activeBondCards = unclosablePositions.map((bond) => {
    const cardData = openedBondDataToActiveBondCardData(bond);
    const url = `/bonds/${bond.bond.uniqTokenName}`;
    return { cardData, url };
  });

  const closableBondCards = closablePositions
    .filter(
      (position) =>
        position.bond.user.walletBondTokenAmount.gt(0) ||
        position.bond.user.walletPoolTokenAmount.gt(0)
    )
    .map((data) => {
      const cardData = openedBondDataToClosableBondCardData(data);
      const url = `/bonds/${data.bond.uniqTokenName}`;
      return { cardData, url, data };
    });

  const closedBondCards = closedBondLenderPositions
    .filter((position) => position.bond.user.walletBondTokenAmount.gt(0))
    .map((data) => {
      const cardData = closedBondDataToClosedPositionCardData(data);
      const url = `/bonds/${data.bond.uniqTokenName}`;
      return { cardData, url, data };
    });

  const openConvertModal =
    (pool: ClosedPool2, bond: OpenedBond2 | ClosedBond2) => () => {
      dispatch(
        setModal({
          type: "convert",
          data: {
            poolCurrencySymbol: pool.poolCurrencySymbol,
            poolTokenName: pool.poolTokenName,
            poolSize: pool.poolSize.toNumber(),
            duration: bond.maxDurationAsEpochs.toNumber(),
            optimFeeBasisPoints: bond.optimFeeBasisPoints.toNumber(),
            defStk: pool.stakeKeyHash ?? network.currentDefStk,
          },
        })
      );
    };

  return (
    <>
      <section className={styles.cardsSection}>
        <h2 className="text-xl font-semibold">Active Bonds to Convert</h2>
        <Text tone="muted">
          Active Bonds represented by EQ Tokens that must be converted to Bond
          Tokens
        </Text>
        <ProductCardsWrapper>
          {!!convertableCards &&
            convertableCards?.map((item) => (
              <ProductCard
                {...item.cardData}
                key={uuidv4()}
                onDetailsButtonClick={() => navigate(item.url)}
              >
                <Button
                  onClick={openConvertModal(item.pool, item.bond)}
                  className="w-full"
                  variant="white"
                >
                  Convert
                </Button>
              </ProductCard>
            ))}
        </ProductCardsWrapper>
      </section>

      <section className={styles.cardsSection}>
        <h2 className="text-xl font-semibold">Active Bonds</h2>
        <Text tone="muted">
          Active Bonds represented by Bond Tokens these tokens can be traded,
          used as collateral, etc.
        </Text>
        <ProductCardsWrapper>
          {!!activeBondCards &&
            activeBondCards?.map((item) => (
              <ProductCard
                {...item.cardData}
                key={uuidv4()}
                onDetailsButtonClick={() => navigate(item.url)}
              >
                <Button
                  onClick={() => navigate(item.url)}
                  className="w-full"
                  variant="white"
                >
                  Bond Details
                </Button>
              </ProductCard>
            ))}
        </ProductCardsWrapper>
      </section>

      <section className={styles.cardsSection}>
        <h2 className="text-xl font-semibold">Closable Bonds</h2>
        <Text tone="muted">
          Active Bonds that can be closed which enables the ‘redeem’ function to
          claim ADA + Interest
        </Text>
        <ProductCardsWrapper>
          {!!closableBondCards &&
            closableBondCards?.map((item, i) => (
              <ProductCard
                {...item.cardData}
                key={uuidv4()}
                onDetailsButtonClick={() => navigate(item.url)}
              >
                {true ? (
                  <Button
                    onClick={() => setCloseModalIndex(i)}
                    className="w-full"
                    variant="white"
                  >
                    Close Position
                  </Button>
                ) : (
                  <Button
                    onClick={() => navigate(item.url)}
                    className="w-full"
                    variant="white"
                  >
                    Bond Details
                  </Button>
                )}
              </ProductCard>
            ))}
        </ProductCardsWrapper>
      </section>

      <section className={styles.cardsSection}>
        <h2 className="text-xl font-semibold">Closed Positions</h2>
        <Text tone="muted">
          Closed non-active Bonds that can be ‘redeemed’ for underlying ADA +
          Interest
        </Text>
        <ProductCardsWrapper>
          {!!closedBondCards &&
            closedBondCards?.map((item, i) => (
              <ProductCard
                {...item.cardData}
                key={uuidv4()}
                onDetailsButtonClick={() => navigate(item.url)}
              >
                {true ? (
                  <Button
                    onClick={() => setRedeemModalIndex(i)}
                    className="w-full"
                    variant="white"
                  >
                    Redeem
                  </Button>
                ) : (
                  <Button
                    onClick={() => navigate(item.url)}
                    className="w-full"
                    variant="white"
                  >
                    Bond Details
                  </Button>
                )}
              </ProductCard>
            ))}
        </ProductCardsWrapper>
      </section>
      {closeModalIndex >= 0 ? (
        <CloseModal
          isOpen={closeModalIndex >= 0}
          setIsOpen={() => setCloseModalIndex(-1)}
          bond={closableBondCards[closeModalIndex].data.bond}
          onClose={() => setCloseModalIndex(-1)}
          txSigningMessage={txSigningMessage}
        />
      ) : (
        <></>
      )}
      {redeemModalIndex >= 0 ? (
        <RedeemModal
          isOpen={redeemModalIndex >= 0}
          setIsOpen={() => setRedeemModalIndex(-1)}
          bond={closedBondCards[redeemModalIndex].data.bond}
          onClose={() => setRedeemModalIndex(-1)}
          txSigningMessage={txSigningMessage}
        />
      ) : (
        <></>
      )}
    </>
  );
};
