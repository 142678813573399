import React, {useRef, useState} from "react";
import { Button } from "../../../components/Button";
import { Modal } from "../../../components/Modal";
import { Attention } from '../../../components/Attention';
import { IssuedBond2 } from '../../../types';
import styles from "./index.module.scss";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {cancelBond, selectCancelJboBondResponse } from "../../../bond/actions";
import {useEffect} from "react";
import {jboTxResponseToAlert} from "../../../bond/utils";
import {setAlert} from "../../../store/slices/alertSlice";
import {formatValue, getBondPositions} from "../../../bond/getters/slice";
import { SpinnerModal } from "../../Modals/SpinnerModal";

interface Props {
  onClose?: () => void;
  isOpen: boolean,
  setIsOpen: (predicate: boolean) => void,
  bond: IssuedBond2,
  txSigningMessage?: string
}

export const CancelModal: React.FC<Props> = ({ isOpen, setIsOpen, bond, onClose, txSigningMessage }) => {
  const dispatch = useAppDispatch()

  const [isSpinnerModalOpen, setIsSpinnerModalOpen] = useState(false);

  const cancelBondResponse = useAppSelector(selectCancelJboBondResponse)

  const prev = useRef({ cancelBondResponse })

  useEffect(() => {
    if (prev.current.cancelBondResponse === cancelBondResponse) {
      prev.current = { cancelBondResponse }
    } else {
      dispatch(setAlert(jboTxResponseToAlert(cancelBondResponse)))
      if (cancelBondResponse !== undefined && cancelBondResponse.tag === 'JboTxSuccess') {
        dispatch(getBondPositions({bondIds: [], bondFlag: 'BondFlagUnwritten'}))
        // dispatch(lookupBondHistoryThunk(bond.uniqTokenName))
        // dispatch(getOpenPoolsThunk())
        // dispatch(getUserBondHistoriesThunk())
        setIsOpen(false)
      }
      setIsSpinnerModalOpen(false)
    }
  }, [dispatch, cancelBondResponse])

  const buttonAction = () => {
    const params = {
      bondWriterAddress: bond.address,
      uniqNftPolicyId: bond.uniqCurrencySymbol,
      uniqNftTokenName: bond.uniqTokenName,
      // uniqTokenName: bond.uniqTokenName,
      // duration: bond.maxDurationAsEpochs.toNumber(),
    }
    dispatch(cancelBond(params))
    setIsSpinnerModalOpen(true)
  }

  return (
    <>
    <Modal open={isOpen} onClose={onClose}>
      <h3>Cancel</h3>
      <Attention>
        Cancelling allows for the redemption of all deposited interest Premium.
        The bond has not activated and no interest has been paid.
      </Attention>
      <div className={styles.total}>
        <p>Total ADA</p>
        <p>{formatValue(bond.totalPremiumPaidAsLovelace)}</p>
      </div>
      <Button
        fullWidth
        size="xxl"
        className={styles.cancelBtn}
        onClick={buttonAction}>
        Cancel
      </Button>
    </Modal>
    <SpinnerModal open={isSpinnerModalOpen} message={txSigningMessage} />
    </>
  );
};
