import { useNavigate } from "react-router-dom";
import styles from "./index.module.scss";
import classNames from "classnames";
import { Button } from "../../../components/Button";
import CustomTitle from "../../../components/Title";
import { Card, makeBondCardData } from "../../../components/Card";
import { ReactSVG } from "react-svg";
import iconWand from "../../../assets/icons/li_wand.svg";

import {lenderInterestRateLabel, formatRateAsPercentAda, makeFutureAirdropDetails, makeValueDetail, makeLenderInterestRateDetail, makeMaxDurationDetail, makePremiumPaidDetail, makeInterestBufferDetail, makeAmountDetail, selectJboBonds as selectJboBonds, borrowerInterestRateLabel, makeBorrowerInterestRateDetail, makeTotalPremiumValueDetail, makeActiveDurationDetail, hasFullInterest, makeCopyableTextDetail, makeDetail, isClosable2, hasClosedBondLender2, selectJboPools, getBondPositions} from "../../../bond/getters/slice";
import {UITypes, OpenedBond2, IssuedBond2, CancelledBond2, ClosedBond2} from "../../../types/ui";

import { v4 as uuidv4 } from "uuid";
import { useAppDispatch, useAppSelector, useInterval } from "../../../store/hooks";
import { useState } from "react";
import { PayInterestModal } from "../PayInterestModal";
import { CancelModal } from "../CancelModal";
import { ChangeKeyModal } from "../ChangeKeyModal";
import { CloseModal } from "../CloseModal";
import { RedeemModal } from "../RedeemModal";


const unwrittenBondToCardData = (bond: IssuedBond2 | CancelledBond2): UITypes.Card.CardData => {
  const bondName = bond.bondName
  return makeBondCardData(
    {
      bondName,
      rateLabel: borrowerInterestRateLabel,
      adaRateFormatted: formatRateAsPercentAda(bond.borrowerInterestRate),
    },
    [
      makeCopyableTextDetail("TxId", bond.utxoRef.txId),
      makeAmountDetail(bond.totalBondTokenAmount),
      makeValueDetail(bond.totalBondTokenAmountAsLovelace),
      makeBorrowerInterestRateDetail(bond.borrowerInterestRate),
      makeMaxDurationDetail(bond.maxDurationAsEpochs),
      makePremiumPaidDetail(bond.totalPremiumPaidAsEpochs),
      makeTotalPremiumValueDetail(bond.totalPremiumPaidAsLovelace, 'Premium Value', 'ADA value of premium paid'),
      makeInterestBufferDetail(bond.interestBufferAsEpochs),
    ],
  )
}

const openedBondToCardData = (bond: OpenedBond2): UITypes.Card.CardData => {
  const chartData = {
    relFirstEpoch: Number(bond.startAsRelativeEpochs),
    relCurrEpoch: Number(bond.nowAsRelativeEpochs),
    relLastEpoch: Number(bond.startAsRelativeEpochs.add(bond.maxDurationAsEpochs)),
    currBufferAsEpochs: bond.currInterestBufferAsEpochs !== null
      ? Number(bond.currInterestBufferAsEpochs)
      : null,
    bufferAsEpochs: Number(bond.interestBufferAsEpochs),
  }
  const bondName = bond.bondName
  const airdropDetails = makeFutureAirdropDetails(bond.verifiedName)
  return makeBondCardData(
    {
      bondName,
      icon: 'diamond',
      rateLabel: lenderInterestRateLabel,
      adaRateFormatted: formatRateAsPercentAda(bond.lenderInterestRate),
      chartData,
    },
    [
      makeDetail("Address", bond.address),
      makeCopyableTextDetail("TxId", bond.utxoRef.txId),
      makeAmountDetail(bond.totalBondTokenAmount),
      makeValueDetail(bond.totalBondTokenAmountAsLovelace),
      makeLenderInterestRateDetail(bond.lenderInterestRate),
      ...airdropDetails,
      makeMaxDurationDetail(bond.maxDurationAsEpochs),
      makePremiumPaidDetail(bond.totalPremiumPaidAsEpochs),
      makeInterestBufferDetail(bond.interestBufferAsEpochs),
    ],
  )
}

const closedBondDataToCardData = (bond: ClosedBond2): UITypes.Card.CardData => {
  const bondName = bond.bondName
  return makeBondCardData(
    {
      bondName,
      rateLabel: borrowerInterestRateLabel,
      adaRateFormatted: formatRateAsPercentAda(bond.borrowerInterestRate),
    },
    [
      makeCopyableTextDetail("TxId", bond.utxoRef.txId),
      makeAmountDetail(bond.totalBondTokenAmount),
      makeValueDetail(bond.totalBondTokenAmountAsLovelace),
      makeBorrowerInterestRateDetail(bond.borrowerInterestRate),
      makeActiveDurationDetail(bond.activeDurationAsEpochs),
      makePremiumPaidDetail(bond.totalPremiumPaidAsEpochs, 'Total Premium Paid', 'Epochs of interest paid'),
      makeTotalPremiumValueDetail(bond.totalPremiumPaidAsLovelace)
    ],
  )
}

const redeemedBondDataToCardData = (bond: ClosedBond2): UITypes.Card.CardData => {
  const bondName = bond.bondName
  return makeBondCardData(
    {
      bondName,
      rateLabel: borrowerInterestRateLabel,
      adaRateFormatted: formatRateAsPercentAda(bond.borrowerInterestRate),
    },
    [
      makeCopyableTextDetail("TxId", bond.utxoRef.txId),
      makeAmountDetail(bond.totalBondTokenAmount),
      makeValueDetail(bond.totalBondTokenAmountAsLovelace),
      makeBorrowerInterestRateDetail(bond.borrowerInterestRate),
      makeActiveDurationDetail(bond.activeDurationAsEpochs),
      makePremiumPaidDetail(bond.totalPremiumPaidAsEpochs, 'Total Premium Paid', 'Epochs of interest paid'),
      makeTotalPremiumValueDetail(bond.totalPremiumPaidAsLovelace)
    ],
  )
}

export const JboBonds = () => {
  const dispatch = useAppDispatch()
  
  useInterval('Bonds', async () => {
    console.log('Jbo Bonds Interval')
    dispatch(getBondPositions({bondIds: [], bondFlag: 'BondFlagAll'}))
    // dispatch(getOpenPoolsThunk())
    // dispatch(getBondHistoriesPagedThunk({ flag: 'Opened', page, size: pageSize }))
  }, 60000, [])
  const [payInterestModalIndex, setPayInterestModalIndex] = useState(-1)
  const [cancelModalIndex, setCancelModalIndex] = useState(-1)
  const [changeStakeKeyModalIndex, setChangeStakeKeyModalIndex] = useState(-1)
  const [closeBondModalIndex, setCloseBondModalIndex] = useState(-1)
  const [redeemBondTokensModalIndex, setRedeemBondTokensModalIndex] = useState(-1)

  const navigate = useNavigate();

  // const [show, setShow] = useState(false);
  // const [value, setValue] = useState(options[0]);

  // const [openedBonds, totalOpenedBondsCount] = useAppSelector(selectPositionsPaged('Opened', isOpenedBondPosition))
  const jboBonds = useAppSelector(selectJboBonds)
  const jboPools = useAppSelector(selectJboPools)
  const openedPools = []
  const cancelledPools = []
  const closedPools = []
  const convertedPools = []
  console.log('Run')
  for (const pool of jboPools) {
    if (pool.tag === 'OpenedPool') {
      if (!pool.isCancelled) {
        openedPools.push(pool)
        // console.log(pool.poolTokenName)
      } else {
        cancelledPools.push(pool)
        console.log(pool.poolTokenName)
        // console.log(pool.poolTokenName)
        // console.log(pool.txOutRef)
      }
    } else if (pool.tag === 'ClosedPool') {
      if (!pool.isConverted) {
        closedPools.push(pool)
      } else {
        convertedPools.push(pool)
      }
    }
  }
  const issuedBonds = []
  const cancelledBonds = []
  const openedBonds = []
  const closedBonds = []
  const redeemedBonds = []
  const yourClosedBonds = []
  for (const bond of jboBonds) {
    if (bond.tag === 'IssuedBond') {
      issuedBonds.push(bond)
    } else if (bond.tag === 'CancelledBond') {
      cancelledBonds.push(bond)
    } else if (bond.tag === 'OpenedBond') {
      openedBonds.push(bond)
    } else {
      // this is kinda broken
      // for testing we want the redeem button but
      // for looking we dont care
      if (!bond.isRedeemed && hasClosedBondLender2(bond)) {
        yourClosedBonds.push(bond)
      }
      if (!bond.isRedeemed) {
      // console.log('Closed')
      // console.log(bond)
        closedBonds.push(bond)
      } else {
      // console.log('Redeemed')
      // console.log(bond)
        redeemedBonds.push(bond)
      }
      // closedBonds.push(bond)
      // if (hasClosedBondLender2(bond))  {
      //   if (hasClosedBondLender2(bond)) {
      //     if (!bond.isRedeemed) {
      //       closedBonds.push(bond)
      //     } else {
      //       redeemedBonds.push(bond)
      //     }
      //   }
      // }
    }
  }

  const issuedBondCards = issuedBonds.map(bond => {
    const cardData = unwrittenBondToCardData(bond)
    const url = `/bonds/${bond.uniqTokenName}`
    return {cardData, url, bond}
  })

  const cancelledBondCards = cancelledBonds.map(bond => {
    const cardData = unwrittenBondToCardData(bond)
    const url = `/bonds/${bond.uniqTokenName}`
    return {cardData, url}
  })


  const openedBondCards = openedBonds.map(bond => {
    const cardData = openedBondToCardData(bond)
    const url = `/bonds/${bond.uniqTokenName}`
    return {cardData, url, bond}
  })

  const closedBondCards = closedBonds.map(bond => {
    const cardData = closedBondDataToCardData(bond)
    const url = `/bonds/${bond.uniqTokenName}`
    return {cardData, url, bond}
  })

  const yourClosedBondCards = yourClosedBonds.map(bond => {
    const cardData = closedBondDataToCardData(bond)
    const url = `/bonds/${bond.uniqTokenName}`
    return {cardData, url, bond}
    })

  const redeemedBondCards = redeemedBonds.map(bond => {
    const cardData = redeemedBondDataToCardData(bond)
    const url = `/bonds/${bond.uniqTokenName}`
    return {cardData, url, bond}
  })

  return (
    <main className={styles.bondsContainer}>
      <CustomTitle title="Jbo Bonds" />
        <>
        <h2 className={styles.cardsSectionTitle}>Opened Pools {openedPools.length}</h2>
        <h2 className={styles.cardsSectionTitle}>Cancelled Pools {cancelledPools.length}</h2>
        <h2 className={styles.cardsSectionTitle}>Closed Pools {closedPools.length}</h2>
        <h2 className={styles.cardsSectionTitle}>Converted Pools {convertedPools.length}</h2>
        <h2 className={styles.cardsSectionTitle}>Issued Bonds {issuedBondCards.length}</h2>
        <h2 className={styles.cardsSectionTitle}>Cancelled Bonds {cancelledBondCards.length}</h2>
        <h2 className={styles.cardsSectionTitle}>Opened Bonds {openedBondCards.length}</h2>
        <h2 className={styles.cardsSectionTitle}>Closed Bonds {closedBondCards.length}</h2>
        <h2 className={styles.cardsSectionTitle}>Redeemed Bonds {redeemedBondCards.length}</h2>
        <section className={styles.cardsSection}>
          <h2 className={styles.cardsSectionTitle}>Issued Jbo Bonds {issuedBondCards.length}</h2>
          <div className={styles.cardsWrapper}>
            {!!issuedBondCards &&
              issuedBondCards?.map((item, i) => (
                  <Card
                    {...item.cardData}
                    key={uuidv4()}
                  >
                    <Button
                      className={classNames(styles.cardButton)}
                      size="xl"
                      clear
                      onClick={ () => setCancelModalIndex(i) }>
                      Cancel
                    </Button>
                  </Card>
              ))}
          </div>
        </section>
        <section className={styles.cardsSection}>
          <h2 className={styles.cardsSectionTitle}>Cancelled Jbo Bonds {cancelledBondCards.length}</h2>
          <div className={styles.cardsWrapper}>
            {!!cancelledBondCards &&
              cancelledBondCards?.map((item) => (
                  <Card
                    {...item.cardData}
                    key={uuidv4()}
                  >
                  </Card>
              ))}
          </div>
        </section>
        <section className={styles.cardsSection} data-testid="issued">
          <h2 className={styles.cardsSectionTitle}>Opened Jbo Bonds {openedBondCards.length}</h2>
          <div className={styles.cardsWrapper}>
            <CustomTitle title="Jbo Bonds" />
            {!!openedBondCards && openedBondCards?.map((item, i) =>
              (
              <Card 
                {...item.cardData}
                key={uuidv4()}
                onDetailsButtonClick={() => navigate(item.url)}
                >
                <Button
                  className={classNames(styles.cardButton)}
                  size="xl"
                  outlined={true}
                  white
                  onClick={() => { setPayInterestModalIndex(i) }}
                  disabled={hasFullInterest(item.bond)}
                  >
                  Pay Interest
                </Button>
                <Button
                  className={styles.cardButton}
                  size="xl"
                  outlined={true}
                  onClick={() => setChangeStakeKeyModalIndex(i)}
                >
                  Change Stake Key
                </Button>
                <Button
                  className={styles.cardButton}
                  size="xl"
                  outlined={true}
                  white
                  onClick={() => setCloseBondModalIndex(i)}
                  disabled={!isClosable2(item.bond)}
                >
                  Close position
                </Button>
              </Card>
              )
            )}
          </div>
        </section>
        <section className={styles.cardsSection}>
          <h2 className={styles.cardsSectionTitle}>Your Closed Jbo Bonds {yourClosedBondCards.length}</h2>
          <div className={styles.cardsWrapper}>
            <CustomTitle title="Jbo Bonds" />
            {!!yourClosedBondCards && yourClosedBondCards?.map((item, i) =>
              (
              <Card 
                {...item.cardData}
                key={uuidv4()}
                onDetailsButtonClick={() => navigate(item.url)}
                >
                <Button
                  white
                  className={styles.cardButton}
                  size="xl"
                  onClick={() => setRedeemBondTokensModalIndex(i)}
                >
                  Redeem
                  <ReactSVG
                    src={iconWand}
                    className={styles.icon}
                  />
                </Button>
              </Card>
              )
            )}
          </div>
        </section>
        <section className={styles.cardsSection}>
          <h2 className={styles.cardsSectionTitle}>Closed Jbo Bonds {closedBondCards.length}</h2>
          <div className={styles.cardsWrapper}>
            <CustomTitle title="Jbo Bonds" />
            {!!closedBondCards && closedBondCards?.map((item) =>
              (
              <Card 
                {...item.cardData}
                key={uuidv4()}
                onDetailsButtonClick={() => navigate(item.url)}
                >
              </Card>
              )
            )}
          </div>
        </section>
        <section className={styles.cardsSection}>
          <h2 className={styles.cardsSectionTitle}>Redeemed Jbo Bonds {redeemedBondCards.length}</h2>
          <div className={styles.cardsWrapper}>
            <CustomTitle title="Jbo Bonds" />
            {!!redeemedBondCards && redeemedBondCards?.map((item) =>
              (
              <Card 
                {...item.cardData}
                key={uuidv4()}
                onDetailsButtonClick={() => navigate(item.url)}
                >
              </Card>
              )
            )}
          </div>
        </section>
        { payInterestModalIndex >= 0
        ? <PayInterestModal
            isOpen={payInterestModalIndex >= 0}
            setIsOpen={() => setPayInterestModalIndex(-1)}
            bond={openedBondCards[payInterestModalIndex].bond}
            onClose={() => setPayInterestModalIndex(-1)} 
          />
        : <></>
        }
        { cancelModalIndex >= 0
        ? <CancelModal
            isOpen={cancelModalIndex >= 0}
            setIsOpen={(p: boolean) => { if (!p) setCancelModalIndex(-1) } }
            bond={issuedBondCards[cancelModalIndex].bond}
            onClose={() => setCancelModalIndex(-1)}
          />
        : <></>
        }
        { changeStakeKeyModalIndex >= 0
        ? <ChangeKeyModal
            isOpen={changeStakeKeyModalIndex >= 0}
            setIsOpen={(p: boolean) => { if (!p) setChangeStakeKeyModalIndex(-1) } }
            bond={openedBondCards[changeStakeKeyModalIndex].bond}
            onClose={() => setChangeStakeKeyModalIndex(-1)}
          />
        : <></>
        }
        { closeBondModalIndex >= 0
        ? <CloseModal
            isOpen={closeBondModalIndex >= 0}
            setIsOpen={(p: boolean) => { if (!p) setCloseBondModalIndex(-1) } }
            bond={openedBondCards[closeBondModalIndex].bond}
            onClose={() => setCloseBondModalIndex(-1)}
          />
        : <></>
        }
        { redeemBondTokensModalIndex >= 0
        ? <RedeemModal
            isOpen={redeemBondTokensModalIndex >= 0}
            setIsOpen={(p: boolean) => { if (!p) setRedeemBondTokensModalIndex(-1) } }
            bond={yourClosedBondCards[redeemBondTokensModalIndex].bond}
            onClose={() => setRedeemBondTokensModalIndex(-1)}
          />
        : <></>
        }

        {/*
        <Button
          white
          className={styles.button}
          size="xl"
          onClick={() => setIsRedeemModalOpen(true)}
        >
          Redeem
          <ReactSVG
            src={iconWand}
            className={styles.icon}
          />
        </Button>
        <RedeemModal
          isOpen={isRedeemModalOpen}
          setIsOpen={(p: boolean) => { setIsRedeemModalOpen(p) } }
          bond={bond}
          onClose={() => setIsRedeemModalOpen(false)}
          txSigningMessage={props.txSigningMessage}
        />
          <Button
            white
            className={styles.button}
            size="xl"
            onClick={() => setIsCloseModalOpen(true)}
          >
            Close position
          </Button>
          <CloseModal
            isOpen={isCloseModalOpen}
            setIsOpen={(p: boolean) => { setIsCloseModalOpen(p) } }
            bond={bond}
            onClose={() => setIsCloseModalOpen(false)}
            txSigningMessage={props.txSigningMessage}
          />
        <Button
          outlined={true}
          className={styles.button}
          size="xl"
          onClick={() => setIsChangeKeyModalOpen(true)}
        >
          Change Stake Key
        </Button>
        <ChangeKeyModal
          isOpen={isChangeKeyModalOpen}
          setIsOpen={(p: boolean) => { setIsChangeKeyModalOpen(p) } }
          bond={bond}
          onClose={() => setIsChangeKeyModalOpen(false)}
        />
        <ReactPaginate
          className={styles.pagesContainer}
          activeClassName={styles.activePageButton}
          pageLinkClassName={styles.pageButton}
          previousLabel="< previous"
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageCount={Math.floor((totalOpenedBondsCount - 1) / pageSize) + 1}
          renderOnZeroPageCount={() => null}
        />
        */}
        </>
    </main>
  );
};
