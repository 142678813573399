import { ReactSVG } from "react-svg";
import { useEffect, useRef, useState } from "react";
import styles from "./index.module.scss";
import eqt from "../../../assets/icons/sphere-512.svg";
import arrowRight from "../../../assets/icons/li_arrow-right_white.svg";
import bond from "../../../assets/icons/diamond-512.svg";
import { Modal } from "../../../components/Modal";
import { UITypes } from "../../../types";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  convertPoolTokens,
  selectConvertPoolTokensResponse,
} from "../../../bond/actions";
import {
  selectWalletTokenCount,
  updateWalletUtxosThunk,
} from "../../../store/slices/walletSlice";
import { setAlert } from "../../../store/slices/alertSlice";
import { jboTxResponseToAlert, resize } from "../../../bond/utils";
import {
  getBondPosition,
  getUserBondPositions,
} from "../../../bond/getters/slice";
import { unsetModal } from "../../../store/slices/modalsSlice";
import { SpinnerModal } from "../SpinnerModal";
import { Button } from "../../../components/ui/button";
import { Text } from "../../../components/ui/typography";

interface Props {
  data: UITypes.Modals.Convert;
  onClose?: () => void;
  txSigningMessage?: string;
}

export const ConvertModal = ({ data, onClose, txSigningMessage }: Props) => {
  const dispatch = useAppDispatch();

  const [isSpinnerModalOpen, setIsSpinnerModalOpen] = useState(false);

  useEffect(() => {
    dispatch(updateWalletUtxosThunk(null));
  }, [dispatch]);

  const convertPoolTokensResponse = useAppSelector(
    selectConvertPoolTokensResponse
  );

  const prev = useRef({ convertPoolTokensResponse });

  useEffect(() => {
    if (prev.current.convertPoolTokensResponse === convertPoolTokensResponse) {
      prev.current = { convertPoolTokensResponse };
    } else {
      dispatch(setAlert(jboTxResponseToAlert(convertPoolTokensResponse)));
      if (
        convertPoolTokensResponse !== undefined &&
        convertPoolTokensResponse.tag === "JboTxSuccess"
      ) {
        (async () => {
          // dispatch(getBondPositions({bondIds: [], bondFlag: 'BondFlagUnwritten'}))
          dispatch(getBondPosition(data.poolTokenName));
          await dispatch(updateWalletUtxosThunk(null));
          dispatch(getUserBondPositions());

          // await dispatch(updateWalletUtxosThunk(null))
          // dispatch(getOpenPoolsThunk())
          // dispatch(getUserBondHistoriesThunk())
          // dispatch(getWrittenBondHistoriesThunk())
        })();
        dispatch(unsetModal());
      }
      setIsSpinnerModalOpen(false);
    }
  }, [dispatch, convertPoolTokensResponse]);

  const walletPoolTokenCountAsBig = useAppSelector(
    selectWalletTokenCount(data.poolCurrencySymbol, data.poolTokenName)
  );
  const walletPoolTokenCount = walletPoolTokenCountAsBig.toNumber();

  const action = async () => {
    const params = {
      poolCurrencySymbol: data.poolCurrencySymbol,
      poolTokenName: data.poolTokenName,
      poolSize: data.poolSize,
      amount: walletPoolTokenCount,
      duration: data.duration,
      optimFeeBasisPoints: data.optimFeeBasisPoints,
      defStk: data.defStk,
    };
    dispatch(convertPoolTokens(params));
    setIsSpinnerModalOpen(true);
  };

  return (
    <>
      <Modal open={true} onClose={onClose}>
        <div className={styles.wrapper}>
          <h3 className="text-2xl">Convert EQT Token</h3>
          <div className={styles.walletLogos}>
            <ReactSVG
              beforeInjection={resize(100, 100)}
              className={styles.icon}
              src={eqt}
            />
            <ReactSVG className={styles.icon} src={arrowRight} />
            <ReactSVG
              beforeInjection={resize(100, 100)}
              className={styles.icon}
              src={bond}
            />
          </div>
          <Button className="w-full" onClick={action}>
            Convert
          </Button>
          <div className={styles.info}>
            <div className={styles.change}>
              <Text tone="muted">You have</Text>
              <Text>{walletPoolTokenCount} EQT</Text>
            </div>
            <div className={styles.exchange}>
              <div className={styles.tokenInfo}>
                <Text tone="muted">You get</Text>
                <Text className={styles.amount}>
                  {walletPoolTokenCount} Bond Token
                </Text>
              </div>
              <ReactSVG className={styles.exchangeIcon} src={bond} />
            </div>
          </div>
        </div>
      </Modal>
      <SpinnerModal open={isSpinnerModalOpen} message={txSigningMessage} />
    </>
  );
};
