import Big from "big.js";
import { Outlet } from "react-router-dom";
import {
  formatDecimalAsPercent,
  formatValue,
  getUserBondPositions,
  isActiveOpenedPoolLenderIssuedBondPosition,
  isOpenedBondLenderPosition,
  isOpenedPoolLenderCancelledBondPosition,
  selectPositions,
} from "../../bond/getters/slice";
import { Tab, Tabs } from "../../components/Tabs";
import { InsightCard } from "../../components/ui/InsightCard";
import { Card } from "../../components/ui/card";
import { Container } from "../../components/ui/container";
import { CustomIcon } from "../../components/ui/custom-icon";
import { Text } from "../../components/ui/typography";
import { useAppDispatch, useAppSelector, useInterval } from "../../store/hooks";
import {
  selectPartialWalletUtxos,
  selectWallet,
} from "../../store/slices/walletSlice";

const tabs: Tab[] = [
  {
    to: "pools",
    label: "Pending OPools",
  },
  {
    to: "lender",
    label: "Bond Positions",
  },
  {
    to: "borrower",
    label: "Borrow Positions",
  },
];

export const YourPage = () => {
  const dispatch = useAppDispatch();

  const wallet = useAppSelector(selectWallet);
  const partialWalletUtxos = useAppSelector(selectPartialWalletUtxos);

  useInterval(
    "YourPage",
    () => {
      const effName = "YourPage";
      console.log(`${effName} ${new Date().getTime()}`);
      console.log(`${effName} - wallet: ${wallet}`);
      console.log(
        `${effName} - partialWalletUtxos: ${partialWalletUtxos.length}`
      );
      if (partialWalletUtxos.length > 0) {
        dispatch(getUserBondPositions());
        console.log(`${effName} - getUserBondPositions`);
      }
    },
    60000,
    [wallet, partialWalletUtxos]
  );

  const uncancelledPoolPositions = useAppSelector(
    selectPositions(
      "BondFlagWritten",
      "User",
      isActiveOpenedPoolLenderIssuedBondPosition
    )
  );
  const cancelledPoolPositions = useAppSelector(
    selectPositions(
      "BondFlagWritten",
      "User",
      isOpenedPoolLenderCancelledBondPosition
    )
  );
  const poolPositionCount =
    uncancelledPoolPositions.length + cancelledPoolPositions.length;

  const openedBondDatas = useAppSelector(
    selectPositions("BondFlagWritten", "User", isOpenedBondLenderPosition)
  );
  const [totalRoiSum, totalRoiValue, totalAmount] = openedBondDatas.reduce(
    ([totalRoi, totalRoiValue, totalAmount], data) => {
      const bond = data.bond;
      const tokenAmount = bond.user.walletBondTokenAmount.add(
        bond.user.walletPoolTokenAmount
      );
      const roiValue = bond.user.accruedInterestAsLovelace.minus(
        bond.user.optimFeeAsLovelace
      );
      if (roiValue.lte(0)) {
        console.log(bond.user.accruedInterestAsLovelace.toNumber());
        console.log(bond.user.optimFeeAsLovelace.toNumber());
      }
      const roi = bond.borrowerInterestRate.mul(tokenAmount);
      const roiFee = roi.mul(bond.user.optimFeeAsLovelace).div(roiValue);
      return [
        totalRoi.add(roi.minus(roiFee)),
        totalRoiValue.add(roiValue),
        totalAmount.add(tokenAmount),
      ];
    },
    [Big(0), Big(0), Big(0)]
  );

  const totalRoi = totalAmount.eq(Big(0))
    ? Big(0)
    : totalRoiSum.div(totalAmount);
  const totalRoiFormatted = "+" + formatDecimalAsPercent(totalRoi);
  const totalRoiValueFormatted = "+" + formatValue(totalRoiValue);

  const poolPositionValuePairs = [
    {
      key: "poolPositions",
      title: "Pool Positions",
      value: poolPositionCount,
    },
  ];
  const bondPositionValuePairs = [
    {
      key: "bondPositions",
      title: "Bond Positions",
      value: openedBondDatas.length,
    },
  ];

  return (
    <Container>
      <section className="grid md:grid-cols-3 gap-4 mb-16">
        <InsightCard icon="droplet" data={poolPositionValuePairs} />
        <InsightCard icon="gem" data={bondPositionValuePairs} />
        <Card>
          <div className="flex flex-col justify-center gap-10 mx-auto">
            <div className="bg-ui-background-sub border-ui-border-sub rounded-full p-4 mx-auto">
              <CustomIcon icon="bar_chart" />
            </div>
            <div className="flex justify-between items-center">
              <div className="flex flex-col gap-1 items-start">
                <Text size="medium" tone="muted">
                  Total Bond ROI
                </Text>
                <Text className="text-xl text-ui-base-green" weight="medium">
                  {totalRoiFormatted}
                </Text>
              </div>
              <div className="flex flex-col gap-1 items-end">
                <Text size="medium" tone="muted">
                  ROI Value
                </Text>
                <Text className="text-xl text-ui-base-green" weight="medium">
                  {totalRoiValueFormatted}
                </Text>
              </div>
            </div>
          </div>
        </Card>
      </section>
      <Tabs links={tabs} />
      <Outlet />
    </Container>
  );
};
