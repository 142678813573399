import Big from "big.js";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactSVG } from "react-svg";
import equityIcon from "../../../assets/icons/sphere-512.svg";
import {
  buyPoolTokens,
  selectBuyPoolTokens2Response,
  selectMatchBondResponse,
} from "../../../bond/actions";
import {
  getBondPosition,
  getBondPositions,
  getUserBondPositions,
  lovelaceToAda,
} from "../../../bond/getters/slice";
import { bondFaceValue } from "../../../bond/getters/ui";
import { jboTxResponseToAlert } from "../../../bond/utils";
import { Attention } from "../../../components/Attention";
import { Modal } from "../../../components/Modal";
import { Slider } from "../../../components/Slider";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { Text } from "../../../components/ui/typography";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setAlert } from "../../../store/slices/alertSlice";
import { unsetModal } from "../../../store/slices/modalsSlice";
import {
  selectWalletAdaAmount,
  updateWalletUtxosThunk,
} from "../../../store/slices/walletSlice";
import { UITypes } from "../../../types";
import { SpinnerModal } from "../SpinnerModal";
import styles from "./index.module.scss";

interface Props {
  data: UITypes.Modals.Deposit;
  onClose?: () => void;
  txSigningMessage?: string;
}

export const DepositModal = ({ data, onClose, txSigningMessage }: Props) => {
  console.log("DepositModal");
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isSpinnerModalOpen, setIsSpinnerModalOpen] = useState(false);

  const [disableFund10Deposit, setDisableFund10Deposit] = useState(false);

  useEffect(() => {
    dispatch(updateWalletUtxosThunk(null));
  }, [dispatch]);

  // TODO: remove after snapshot has passed
  useEffect(() => {
    const oneHour = 60 * 60 * 1000;
    const now = Number(new Date());
    const fund10Snapshot = 1692392400000;
    const epoch431Start = 1692395100000;
    setDisableFund10Deposit(
      data.duration == 1 &&
        now >= fund10Snapshot - oneHour &&
        now <= epoch431Start + oneHour
    );
  });

  const buyPoolTokensResponse = useAppSelector(selectBuyPoolTokens2Response);
  const matchBondResponse = useAppSelector(selectMatchBondResponse);

  const prev = useRef({ buyPoolTokensResponse, matchBondResponse });

  useEffect(() => {
    if (prev.current.buyPoolTokensResponse !== buyPoolTokensResponse) {
      dispatch(setAlert(jboTxResponseToAlert(buyPoolTokensResponse)));
      if (
        buyPoolTokensResponse !== undefined &&
        buyPoolTokensResponse.tag === "JboTxSuccess"
      ) {
        (async () => {
          // not efficient
          dispatch(
            getBondPositions({ bondIds: [], bondFlag: "BondFlagUnwritten" })
          );
          dispatch(getBondPosition(data.poolTokenName));
          await dispatch(updateWalletUtxosThunk(null));
          dispatch(getUserBondPositions());
        })();
        dispatch(unsetModal());
      }
      setIsSpinnerModalOpen(false);
      prev.current = { buyPoolTokensResponse, matchBondResponse };
    } else if (prev.current.matchBondResponse !== matchBondResponse) {
      dispatch(setAlert(jboTxResponseToAlert(matchBondResponse)));
      if (
        matchBondResponse !== undefined &&
        matchBondResponse.tag === "JboTxSuccess"
      ) {
        (async () => {
          dispatch(
            getBondPositions({ bondIds: [], bondFlag: "BondFlagUnwritten" })
          );
          dispatch(getBondPosition(data.poolTokenName));
          await dispatch(updateWalletUtxosThunk(null));
          dispatch(getUserBondPositions());
        })();
        dispatch(unsetModal());
        navigate("/your-page/lender");
      }
      setIsSpinnerModalOpen(false);
      prev.current = {
        buyPoolTokensResponse,
        matchBondResponse: matchBondResponse,
      };
    }
  }, [
    dispatch,
    navigate,
    buyPoolTokensResponse,
    matchBondResponse,
    data.poolTokenName,
  ]);

  const walletAdaAmount = useAppSelector(selectWalletAdaAmount);
  const poolTokenCount = Big(data.poolTokenCount);

  const walletAdaAmountAsString = walletAdaAmount.toNumber();

  const bondFaceValueAsAda = lovelaceToAda(Big(bondFaceValue)).toNumber();

  const maxBuyablePoolTokens = walletAdaAmount
    .div(Big(bondFaceValueAsAda))
    .round(0, Big.roundDown);

  const minPoolTokenCount = maxBuyablePoolTokens.gt(poolTokenCount)
    ? poolTokenCount
    : maxBuyablePoolTokens;

  const minPoolTokenPurchaseAmount = 0;
  const maxPoolTokenPurchaseAmount = minPoolTokenCount.toNumber();

  const minSliderValue = minPoolTokenPurchaseAmount;
  const maxSliderValue = maxPoolTokenPurchaseAmount;
  const sliderStepValue = 1;

  const [poolTokenPurchaseAmount, setPoolTokenPurchaseAmount] = useState(
    minPoolTokenPurchaseAmount
  );

  const params = {
    poolCurrencySymbol: data.poolCurrencySymbol,
    poolTokenName: data.poolTokenName,
    poolSize: data.poolSize,
    purchaseAmount: poolTokenPurchaseAmount,
    bondWriterUtxoRef: data.bondWriterUtxoRef,
    duration: data.duration,
    optimFeeBasisPoints: data.optimFeeBasisPoints,
    defStk: data.defStk,
  };

  const poolTokenPurchaseAmountAsAda =
    poolTokenPurchaseAmount * bondFaceValueAsAda;

  const depositAdaAction = (poolTokenPurchaseAmount: number) => async () => {
    if (poolTokenPurchaseAmount === 0) return;
    dispatch(buyPoolTokens(params));
    setIsSpinnerModalOpen(true);
  };

  return (
    <>
      <Modal open={true} onClose={onClose}>
        <div className={styles.wrapper}>
          <h2 className="text-xl font-normal mb-6">Deposit</h2>
          <Text tone="muted">1 EQT = 100 ₳</Text>
          <div className="py-2 flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <Input
                className="w-[180px]"
                value={
                  poolTokenPurchaseAmount === 0
                    ? undefined
                    : poolTokenPurchaseAmount
                }
                onChange={(event) => {
                  const value = Number(event.target.value);
                  setPoolTokenPurchaseAmount(
                    value > maxSliderValue
                      ? maxSliderValue
                      : value < minSliderValue
                      ? minSliderValue
                      : value
                  );
                }}
                placeholder={maxSliderValue <= 0 ? "Not enough ₳" : "0"}
              />
              <div className="flex flex-col items-end">
                <Text tone="muted">Amount</Text>
                <Text className="text-[30px]">
                  {poolTokenPurchaseAmountAsAda}₳
                </Text>
              </div>
            </div>
            <Slider
              onChange={(value) => setPoolTokenPurchaseAmount(value)}
              value={poolTokenPurchaseAmount}
              min={minSliderValue}
              max={maxSliderValue}
              step={sliderStepValue}
            />
            <div className="flex justify-between items-center mt-3 mb-5">
              <Text tone="muted">Available amount in wallet</Text>

              <Text>{walletAdaAmountAsString} ₳</Text>
            </div>
            <Button
              className="w-full"
              disabled={disableFund10Deposit}
              onClick={depositAdaAction(poolTokenPurchaseAmount)}
            >
              Deposit
            </Button>
            {disableFund10Deposit && (
              <Attention>
                Deposits have been temporarily disabled for the Catalyst Fund10
                snapshot.
              </Attention>
            )}
            <div className={styles.equity}>
              <div className={styles.label}>
                <ReactSVG src={equityIcon} /> Equity Tokens
              </div>
              <div>
                {poolTokenPurchaseAmount}
                EQADA
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <SpinnerModal open={isSpinnerModalOpen} message={txSigningMessage} />
    </>
  );
};
