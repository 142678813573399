import { NavLink } from "react-router-dom";

const links = [
  {
    label: "Overview",
    path: "/dashboard",
  },
  // {
  //   label: "Pools",
  //   path: "/pools",
  // },
  {
    label: "Bonds",
    path: "/bonds",
  },
  {
    label: "Your Page",
    path: "/your-page",
  },
  {
    label: "Learn",
    path: "/faq",
  },
  // {
  //   label: "ILE",
  //   path: "/ile",
  // },
];

export const MainMenu = () => (
  <ul className="flex order-4 flex-1 md:flex-initial gap-8 mt-4 md:mt-0 justify-between md:order-2">
    {links.map((link) => (
      <li key={link.path}>
        <NavLink to={link.path} className="text-ui-surface-sub">
          {link.label}
        </NavLink>
      </li>
    ))}
  </ul>
);
