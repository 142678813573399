import Transport from "@ledgerhq/hw-transport"
import TransportWebHid from "@ledgerhq/hw-transport-webhid"
import TransportWebUsb from "@ledgerhq/hw-transport-webusb"
import TransportWebBle from "@ledgerhq/hw-transport-web-ble"
import Ada, { DeviceStatusCodes } from '@cardano-foundation/ledgerjs-hw-app-cardano';
import { useAppDispatch } from '../hooks';
import { useState, useEffect, useContext } from "react";
import { setWalletByLedgerApp } from '../slices/walletSlice';
import { Alert } from './../../components/Alert';
import { setAlert } from "../slices/alertSlice";
import { WebsocketContext } from "../../websocket";

export type LedgerTransportType
  = "LedgerTransportWebHid"
  | "LedgerTransportWebUsb"
  | "LedgerTransportBluetooth"

export type ConnectLedger = (transportType: LedgerTransportType, account: number) => void
export type DisconnectLedger = () => void
export type LedgerStatus = () => boolean

export type LedgerHandle = {
  connectLedger: ConnectLedger,
  disconnectLedger: DisconnectLedger
  ledgerStatus: LedgerStatus
}

export function useLedgerWallet(): LedgerHandle {
  const ws = useContext(WebsocketContext)
  const [ledgerTransport, setLedgerTransport] = useState<Transport | null>(null)
  const [adaApp, setAdaApp] = useState<Ada | null>(null)
  const [account, setAccount] = useState<number>(0)
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    if (ledgerTransport === null)
      return
    setAdaApp(new Ada(ledgerTransport))
  }, [ledgerTransport])

  useEffect(() => {
    if (adaApp === null) 
      return;
    adaApp.getVersion().then(() => {
      dispatch(setWalletByLedgerApp({adaApp, account, ws}))
    }).catch(err => {
      switch (err.code) {
      case DeviceStatusCodes.ERR_CLA_NOT_SUPPORTED:
      case 0x6E01:
        dispatch(setAlert({
          type: 'warning' as const,
          message: 'Could not communicate with device, ensure the Cardano app is open',
        }))
        setAdaApp(null)
        break
      }
    })
  }, [adaApp, account])

  const connectLedger = (transportType: LedgerTransportType, accountChoice?: number) => {
    const transportLookup = {
      "LedgerTransportWebHid": () => TransportWebHid.openConnected().then(transport => transport || TransportWebHid.create()),
      "LedgerTransportWebUsb": () => TransportWebUsb.openConnected().then(transport => transport || TransportWebUsb.create()),
      "LedgerTransportBluetooth": () => TransportWebBle.create()
    }
    const createTransport = transportLookup[transportType]
    if (ledgerTransport !== null)
      ledgerTransport.close()
    createTransport().then(setLedgerTransport).catch (_err => {
      dispatch(setAlert({
        type: 'error' as const,
        message: 'Unknown error while attempting to connect to Ledger device',
      }))
    })
    if (accountChoice)
      setAccount(accountChoice)
  }

  const disconnectLedger = () => {
    if (ledgerTransport === null)
      return

    ledgerTransport.close()
    setLedgerTransport(null)
  }

  const ledgerStatus = () => {
    return adaApp !== null
  }

  return {connectLedger, disconnectLedger, ledgerStatus}
}
