import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { DotsLink } from "../../../components/DotsLink";
import { Card } from "../../../components/ui/card";
import { Text } from "../../../components/ui/typography";
dayjs.extend(relativeTime);

export const BondHistory = () => {
  return (
    <Card className="grid gap-6">
      <h3 className="text-xl">History</h3>

      <ul>
        <li>
          <Text size="medium" weight="medium">
            20,000 ₳
          </Text>
          <div className="flex gap-1 items-center">
            <Text tone="muted" className="whitespace-nowrap">
              Staked by
            </Text>
            <DotsLink path="/" isExternal>
              asset1vhwpufc7l6pek485nk7vvt0jkasset1vhwpufc7l6pek485nk7vvt0jk
            </DotsLink>
          </div>
          <Text tone="muted">{dayjs(new Date()).fromNow()}</Text>
        </li>
      </ul>
    </Card>
  );
};
