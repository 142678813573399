import { useState } from "react";
import { FiCopy } from "react-icons/fi";
import {
  hasClosedBondLender2,
  hasFullInterest,
  hasOpenedBondBorrower2,
  hasOpenedBondLender2,
  isCancellable,
  isClosable2,
  isOpenedPoolCancelledBondPosition,
} from "../../../bond/getters/slice";
import { copyToClipboard } from "../../../bond/utils";
import { BondChart } from "../../../components/BondChart";
import { Button } from "../../../components/ui/button";
import { Card } from "../../../components/ui/card";
import { CustomIcon } from "../../../components/ui/custom-icon";
import { Text } from "../../../components/ui/typography";
import { network } from "../../../network";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { setModal } from "../../../store/slices/modalsSlice";
import { selectWallet } from "../../../store/slices/walletSlice";
import { Bond2, BondPosition, Pool2 } from "../../../types/ui";
import { CancelModal } from "../../Modals/CancelModal";
import { ChangeKeyModal } from "../../Modals/ChangeKeyModal";
import { CloseModal } from "../../Modals/CloseModal";
import { DonateModal } from "../../Modals/DonateModal";
import { PayInterestModal } from "../../Modals/PayInterestModal";
import { RedeemModal } from "../../Modals/RedeemModal";

export const BondHealth = (props: {
  position: BondPosition;
  txSigningMessage?: string;
}) => {
  const dispatch = useAppDispatch();
  const { position } = props;
  const wallet = useAppSelector(selectWallet);
  console.log("bondhealth");
  console.log(position);

  const [isPayInterestModalOpen, setIsPayInterestModalOpen] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isChangeKeyModalOpen, setIsChangeKeyModalOpen] = useState(false);
  const [isCloseModalOpen, setIsCloseModalOpen] = useState(false);
  const [isRedeemModalOpen, setIsRedeemModalOpen] = useState(false);
  const [isDonateModalOpen, setIsDonateModalOpen] = useState(false);

  const openConvertModal = (bond: Bond2, pool: Pool2) => () => {
    dispatch(
      setModal({
        type: "convert",
        data: {
          poolCurrencySymbol: pool.poolCurrencySymbol,
          poolTokenName: pool.poolTokenName,
          poolSize: pool.poolSize.toNumber(),
          duration: bond.maxDurationAsEpochs.toNumber(),
          optimFeeBasisPoints: bond.optimFeeBasisPoints.toNumber(),
          defStk: pool.stakeKeyHash ?? network.currentDefStk,
        },
      })
    );
  };

  const pool = position.pool;
  const bond = position.bond;

  const convertButton =
    bond.tag === "OpenedBond" ? (
      pool !== null ? (
        hasOpenedBondLender2(bond) && bond.user.walletPoolTokenAmount.gt(0) ? (
          <Button
            size="lg"
            className="w-full"
            onClick={openConvertModal(bond, pool)}
          >
            Convert
          </Button>
        ) : (
          <></>
        )
      ) : (
        <></>
      )
    ) : bond.tag === "ClosedBond" ? (
      pool !== null ? (
        hasClosedBondLender2(bond) && bond.user.walletPoolTokenAmount.gt(0) ? (
          <Button
            size="lg"
            className="w-full"
            onClick={openConvertModal(bond, pool)}
          >
            Convert
          </Button>
        ) : (
          <></>
        )
      ) : (
        <></>
      )
    ) : (
      <></>
    );

  const closeButton =
    wallet !== null ? (
      bond.tag === "OpenedBond" ? (
        isClosable2(bond) ? (
          <div>
            <Button
              size="lg"
              className="w-full"
              onClick={() => setIsCloseModalOpen(true)}
            >
              Close position
            </Button>
            <CloseModal
              isOpen={isCloseModalOpen}
              setIsOpen={(p: boolean) => {
                setIsCloseModalOpen(p);
              }}
              bond={bond}
              onClose={() => setIsCloseModalOpen(false)}
              txSigningMessage={props.txSigningMessage}
            />
          </div>
        ) : (
          <></>
        )
      ) : (
        <></>
      )
    ) : (
      <></>
    );

  const redeemButton =
    bond.tag === "ClosedBond" ? (
      hasClosedBondLender2(bond) && bond.user.walletBondTokenAmount.gt(0) ? (
        <div>
          <Button
            size="lg"
            className="w-full"
            onClick={() => setIsRedeemModalOpen(true)}
          >
            Redeem
          </Button>
          <RedeemModal
            isOpen={isRedeemModalOpen}
            setIsOpen={(p: boolean) => {
              setIsRedeemModalOpen(p);
            }}
            bond={bond}
            onClose={() => setIsRedeemModalOpen(false)}
            txSigningMessage={props.txSigningMessage}
          />
        </div>
      ) : (
        <></>
      )
    ) : (
      <></>
    );

  const payInterestButton =
    bond.tag === "OpenedBond" ? (
      hasOpenedBondBorrower2(bond) ? (
        <div>
          <Button
            size="lg"
            className="w-full"
            onClick={() => setIsPayInterestModalOpen(true)}
            disabled={hasFullInterest(bond)}
          >
            Pay Interest
          </Button>
          <PayInterestModal
            isOpen={isPayInterestModalOpen}
            setIsOpen={(predicate: boolean) =>
              setIsPayInterestModalOpen(predicate)
            }
            bond={bond}
            onClose={() => setIsPayInterestModalOpen(false)}
            txSigningMessage={props.txSigningMessage}
          />
        </div>
      ) : (
        <></>
      )
    ) : (
      <></>
    );

  const changeStakeKeyButton =
    bond.tag === "OpenedBond" ? (
      hasOpenedBondBorrower2(bond) ? (
        <div>
          <Button
            size="lg"
            className="w-full"
            variant="secondary"
            onClick={() => setIsChangeKeyModalOpen(true)}
          >
            Change Stake Key
          </Button>
          <ChangeKeyModal
            isOpen={isChangeKeyModalOpen}
            setIsOpen={(p: boolean) => {
              setIsChangeKeyModalOpen(p);
            }}
            bond={bond}
            onClose={() => setIsChangeKeyModalOpen(false)}
          />
        </div>
      ) : (
        <></>
      )
    ) : (
      <></>
    );

  const donateButton =
    wallet !== null ? (
      bond.tag === "OpenedBond" && !hasOpenedBondBorrower2(bond) ? (
        <div>
          <Button
            className="w-full"
            variant="secondary"
            onClick={() => setIsDonateModalOpen(true)}
            disabled={hasFullInterest(bond)}
          >
            Donate Interest
          </Button>
          <DonateModal
            isOpen={isDonateModalOpen}
            setIsOpen={(p: boolean) => {
              setIsDonateModalOpen(p);
            }}
            bond={bond}
            onClose={() => setIsDonateModalOpen(false)}
            txSigningMessage={props.txSigningMessage}
          />
        </div>
      ) : (
        <></>
      )
    ) : (
      <></>
    );

  const cancelButton =
    bond.tag === "IssuedBond" ? (
      bond.user.tag === "Borrower" && isCancellable(pool, bond) ? (
        <>
          <Button
            className="w-full"
            variant="secondary"
            onClick={() => setIsCancelModalOpen(true)}
          >
            Cancel
          </Button>
          <CancelModal
            isOpen={isCancelModalOpen}
            setIsOpen={(p: boolean) => {
              setIsCancelModalOpen(p);
            }}
            bond={bond}
            onClose={() => setIsCancelModalOpen(false)}
            txSigningMessage={props.txSigningMessage}
          />
        </>
      ) : (
        <></>
      )
    ) : (
      <></>
    );

  const tokenName = isOpenedPoolCancelledBondPosition(position)
    ? position.pool.poolTokenName
    : bond.uniqTokenName;

  // if (bond.tag === 'OpenedBond') {
  // console.log('HEHEHEHEHEH')
  // console.log(bond.currPremiumPaidAsLovelace === null ? 0 : bond.currPremiumPaidAsLovelace.toString())
  // console.log(bond.rewardsPerEpochAsLovelace.toString())
  // console.log(bond.currInterestBufferAsEpochs?.toString())
  // }
  const chartData =
    bond.tag === "OpenedBond"
      ? {
          relFirstEpoch: Number(bond.startAsRelativeEpochs),
          relCurrEpoch: Number(bond.nowAsRelativeEpochs),
          relLastEpoch: Number(
            bond.startAsRelativeEpochs.add(bond.maxDurationAsEpochs)
          ),
          currBufferAsEpochs:
            bond.currInterestBufferAsEpochs !== null
              ? Number(bond.currInterestBufferAsEpochs)
              : null,
          bufferAsEpochs: Number(bond.interestBufferAsEpochs),
          hasNoBorder: true,
        }
      : null;

  return (
    <Card className="grid gap-6 ">
      <h3 className="text-xl text-semibold mx-auto">Bond Health</h3>

      {chartData !== null ? <BondChart {...chartData} /> : <></>}
      <div className="p-6 bg-ui-background-sub border border-ui-border-sub rounded-xl flex justify-between items-center">
        <div className="flex items-center">
          <CustomIcon icon="diamond" className="h-12 w-12 mr-2" />

          <Text size="medium" weight="medium" title={tokenName}>
            Bond Tokens
          </Text>
        </div>
        <div className="flex flex-col items-end">
          <Text tone="muted" size="medium">
            Bond Token ID
          </Text>
          <Text
            className="flex items-center cursor-pointer"
            onClick={() => copyToClipboard(tokenName)}
          >
            Copy ID <FiCopy className="h-4 w-4 ml-1" />
          </Text>
        </div>
      </div>
      <div className="grid gap-4">
        {payInterestButton}
        {changeStakeKeyButton}
        {convertButton}
        {redeemButton}
        {closeButton}
        {cancelButton}
        {donateButton}
      </div>
    </Card>
  );
};
