import { DotsLink } from "../../../components/DotsLink";
import { Card } from "../../../components/ui/card";

export const BondExplorer = (props: { stakeAddress: string }) => {
  return (
    <Card className="flex justify-between items-center">
      <h3 className="text-xl">Explorer</h3>
      <DotsLink
        path={`https://pool.pm/${props.stakeAddress}`}
        isExternal={true}
      >
        View on Pool.pm
      </DotsLink>
    </Card>
  );
};
