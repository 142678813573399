import { FC, useContext, useEffect, useRef, useState } from "react";
import { ReactSVG } from "react-svg";
import logo from '../../../assets/images/Logomark.svg';
import walletIcon from '../../../assets/icons/li_wallet.svg';
import copy from '../../../assets/icons/li_copy.svg';
import external from '../../../assets/icons/li_external-link.svg';
import logout from '../../../assets/icons/li_log-out.svg';
import styles from './index.module.scss';
import ada from '../../../assets/icons/ada.svg';
import { Button } from "../../../components/Button";
import classNames from "classnames";
import {useAppDispatch, useAppSelector} from "../../../store/hooks";
import {disconnectWalletThunk, selectWallet, selectWalletBondTokenAssets, selectWalletLovelaceAmount, selectWalletOptimNft, selectWalletPoolTokenAssets} from "../../../store/slices/walletSlice";
import {formatAmount, formatValue, lovelaceToAda, selectVerifiedNameMap} from "../../../bond/getters/slice";
import equityIcon from "../../../assets/icons/sphere-512.svg";
import bondIcon from "../../../assets/icons/diamond-512.svg";
import {copyToClipboard, jboTxResponseToAlert, tokenNameToVerifiedName} from "../../../bond/utils";
import {collectFees, selectCollectFeesResponse} from "../../../bond/actions";
import {selectPoolSize} from "../../../bond/getters/slice";
import Big from "big.js";
import { DisconnectLedger } from '../../../store/hooks/ledgerWallet';
import {setAlert} from "../../../store/slices/alertSlice";
import { unsetModal } from "../../../store/slices/modalsSlice";
import { SpinnerModal } from "../../Modals/SpinnerModal";
import { WebsocketContext } from "../../../websocket";

interface Props {
  disconnectLedger: DisconnectLedger
  txSigningMessage?: string
}

export const WalletDetails: FC<Props> = ({disconnectLedger, txSigningMessage}) => {
  const dispatch = useAppDispatch()
  const ws = useContext(WebsocketContext)
  const [isVisible, setVisible] = useState(false);

  let timerId = 0;

  const onShow = () => {
    clearTimeout(timerId)
    setVisible(true)
  }

  const onHide = () => {
    timerId = setTimeout(() => {
      setVisible(false)
    }, 300) as any
  }

  const collectFeesResponse = useAppSelector(selectCollectFeesResponse)

  const prev = useRef({ collectFeesResponse })

  const [isSpinnerModalOpen, setIsSpinnerModalOpen] = useState(false);
  useEffect(() => {
    if (prev.current.collectFeesResponse === collectFeesResponse) {
      prev.current = { collectFeesResponse }
    } else {
      dispatch(setAlert(jboTxResponseToAlert(collectFeesResponse)))
      if (collectFeesResponse !== undefined && collectFeesResponse.tag === 'JboTxSuccess') {
        dispatch(unsetModal())
      }
      setIsSpinnerModalOpen(false)
    }
  }, [dispatch, collectFeesResponse])


  const currencySymbolToPoolSize = useAppSelector(selectPoolSize)

  const verifiedNameMap = useAppSelector(selectVerifiedNameMap)
  const toVerifiedName = tokenNameToVerifiedName(verifiedNameMap)
  const wallet = useAppSelector(selectWallet)
  const walletAmountAsLovelace = useAppSelector(selectWalletLovelaceAmount)
  const walletBondTokenMap = useAppSelector(selectWalletBondTokenAssets)
  const walletPoolTokenMap = useAppSelector(selectWalletPoolTokenAssets)
  const walletOptimNft = useAppSelector(selectWalletOptimNft)
  // honestly this code is trash and we should probably have the verified name map
  // contain cs, tn combinations so we don't have to do gymnastics
  const walletPoolTokenRows = Object.entries(walletPoolTokenMap).map(([poolCs, quantityMap]) => {
    return Object.entries(quantityMap).map(([name, amount]) => {
      const poolSize = currencySymbolToPoolSize(poolCs)
      return poolSize === null
        ? <></>
        : <tr key={name}>
            <td><img className={styles.icon} width="26" height="26" src={equityIcon} alt={name} />
              {toVerifiedName(name, Big(poolSize))}
            </td>
            <td>{formatAmount(amount)}</td>
          </tr>
    })
  })

  const walletBondTokenRows =  Object.entries(walletBondTokenMap).map(([_bondCs, quantityMap]) => {
    return Object.entries(quantityMap).map(([name, amount]) => {
      const verifiedNames = verifiedNameMap[name]
      return verifiedNames === undefined
        ? <tr key={name}>
            <td><img className={styles.icon} width="26" height="26" src={bondIcon} alt={name} />{name}</td>
            <td>{formatAmount(amount)}</td>
          </tr>
        : Object.entries(verifiedNames).map(([size, _vname]) => {
            return (
              <tr key={name}>
                <td><img className={styles.icon} width="26" height="26" src={bondIcon} alt={name} />{toVerifiedName(name, Big(size))}</td>
                <td>{formatAmount(amount)}</td>
              </tr>
            )
          })
    })
  }) 
  if (wallet === null) setVisible(false)

  return (
    <>
    <div className={styles.walletDetailsWrapper} 
      onMouseEnter={onShow} 
      onMouseLeave={onHide}>

      <div className={classNames(styles.walletDetailsButton, { [styles.open]: isVisible })}>
        <span className={styles.detailsAmount}>{formatValue(walletAmountAsLovelace)}</span>
        <span className={styles.detailsAddress}>{wallet !== null ? wallet.address : ''}</span>
      </div>

      {
        (isVisible && wallet !== null) && (
          <div className={styles.container}>
            <div className={styles.title}>
              <ReactSVG className={styles.logo} src={logo} /> Account
              { walletOptimNft !== null
              ? <Button onClick={() => dispatch(collectFees(walletOptimNft))}>COLLECT</Button>
              : <></>
              }
            </div>
            <div className={styles.address}>
              <Button clear>
                <ReactSVG className={styles.icon} src={walletIcon} />
                <span className={styles.value}>
                  {wallet.address}
                </span>
              </Button>
            </div>
            <div className={styles.buttons}>
              <Button clear size="sm" onClick={() => copyToClipboard(wallet.address)}>
                <ReactSVG src={copy} className={styles.icon} />
                Copy Address
              </Button>
              <a href={`https://cardanoscan.io/address/${wallet.address}`} target="_blank" rel="noreferrer">
                <Button clear size="sm" fullWidth>
                  <ReactSVG src={external} className={styles.icon} />
                  Explorer
                </Button>
              </a>
            </div>
            <div className={styles.box}>
              <table className={styles.wallets}>
                <tbody>
                  <tr key={'ADA'}>
                    <td><img className={styles.icon} src={ada} alt={'ADA'} />{'ADA'}</td>
                    <td>{lovelaceToAda(walletAmountAsLovelace).toLocaleString()}</td>
                  </tr>
                  {walletPoolTokenRows}
                  {walletBondTokenRows}
                </tbody>
              </table>
            </div>
            <Button
              size="sm"
              secondary
              className={styles.disconnect}
              onClick={() => {
                disconnectLedger()
                dispatch(disconnectWalletThunk({ws}))
              }}>
              <ReactSVG className={styles.icon} src={logout} />
              Disconnect
            </Button>
          </div>
        )
      }
    </div>
    <SpinnerModal open={isSpinnerModalOpen} message={txSigningMessage} />
    </>
  )
}
