import Big from "big.js";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import "../../assets/base/shared.scss";
import {
  formatAmount,
  formatValue,
  getBondPositions,
  getHistoricalData,
  isActiveOpenedPoolIssuedBondPosition,
  makeAmountAvailableDetail,
  makeFutureAirdropDetails,
  makeInterestBufferDetail,
  makeLenderInterestRateDetail,
  makeMaxDurationDetail,
  makePremiumPaidDetail,
  makeValueDetail,
  selectHistoricalData,
  selectPositions,
  selectVerifiedNameMap,
} from "../../bond/getters/slice";
import { getVerifiedName } from "../../bond/utils";
import CustomTitle from "../../components/Title";
import { network } from "../../network";
import { useAppDispatch, useAppSelector, useInterval } from "../../store/hooks";
import { OpenedPoolIssuedBondPosition, UITypes } from "../../types/ui";
import { VerifyBond } from "./VerifyBond";
import { FiArrowRight } from "react-icons/fi";
import { InsightCard } from "../../components/ui/InsightCard";
import { ProductCard, makePoolCardData } from "../../components/ui/ProductCard";
import { Button } from "../../components/ui/button";
import { Card } from "../../components/ui/card";
import { Container } from "../../components/ui/container";
import { CustomIcon } from "../../components/ui/custom-icon";
import { Text } from "../../components/ui/typography";
import { ProductCardsWrapper } from "../../components/ui/ProductCardsWrapper";
import { VerifyBondModal } from "../Modals/VerifyBondModal";

const openedPoolDataToCardData = (
  position: OpenedPoolIssuedBondPosition
): UITypes.Card.CardData => {
  const pool = position.pool;
  const bond = position.bond;
  const poolName = pool.poolName;

  const lineColor = pool.fundedRatio.lt(Big(1)) ? "violet" : undefined;
  const airdropDetails = makeFutureAirdropDetails(bond.verifiedName);
  return makePoolCardData(
    poolName,
    pool.fundedRatio,
    pool.poolTokenBoughtAmountAsLovelace,
    [
      makeAmountAvailableDetail(pool.poolSize.sub(pool.poolTokenBoughtAmount)),
      makeValueDetail(bond.totalBondTokenAmountAsLovelace),
      makeLenderInterestRateDetail(bond.lenderInterestRate),
      ...airdropDetails,
      makeMaxDurationDetail(bond.maxDurationAsEpochs),
      makePremiumPaidDetail(bond.totalPremiumPaidAsEpochs),
      makeInterestBufferDetail(bond.interestBufferAsEpochs),
    ],
    lineColor
  );
};

export const Dashboard = () => {
  const dispatch = useAppDispatch();

  useInterval(
    "Dashboard",
    async () => {
      console.log("Dashboard Interval");
      dispatch(
        getBondPositions({ bondIds: [], bondFlag: "BondFlagUnwritten" })
      );
      console.log("Dashboard dispatched getOpenPoolsThunk");
      dispatch(getHistoricalData());
      console.log("Dashboard dispatched getHistoricalDataThunk");
    },
    60000
  );

  const navigate = useNavigate();

  const openedPoolIssuedBondPositions = useAppSelector(
    selectPositions(
      "BondFlagUnwritten",
      "All",
      isActiveOpenedPoolIssuedBondPosition
    )
  );

  const verifiedNameMap = useAppSelector(selectVerifiedNameMap);

  // First sort all the pools
  //   - by lender interest rate descending and if tied by oldest to newest pool
  // Then sort the sorted pools into categories by duration
  // Then for each category
  //   filter for verified pools and use those only
  //   if there are no verified pools use unverified pools
  //   of those pools grab the first 6
  type CardInfo = {
    cardData: UITypes.Card.CardData;
    url: string;
    position: OpenedPoolIssuedBondPosition;
    mbSlotIssuedAt: Big | undefined;
    isVerified: boolean;
  };
  const cardInfos = openedPoolIssuedBondPositions
    .map((position) => {
      const cardData = openedPoolDataToCardData(position);
      const pool = position.pool;
      const poolTokenName = pool.poolTokenName;
      const url = `/pools/${poolTokenName}`;
      const isManuallyVerified =
        getVerifiedName(verifiedNameMap, poolTokenName, pool.poolSize) !== null;
      const isVerified = pool.poolName !== pool.poolTokenName;
      // const manuallyVerifiedScore = Big(isManuallyVerified ? 100 : 0)
      const lenderApyScore = position.bond.lenderInterestRate;
      const mbSlotIssuedAt = position.bond.issuedAt;
      // const fundedRatioScore = pool.fundedRatio.div(10)
      // console.log(manuallyVerifiedScore.toNumber())
      // console.log(lenderApyScore.toNumber())
      // console.log(fundedRatioScore.toNumber())
      const score = lenderApyScore;
      return { cardData, url, position, score, mbSlotIssuedAt, isVerified };
    })
    .sort((a, b) => {
      if (a.score.gt(b.score)) return -1;
      else if (a.score.lt(b.score)) return 1;
      else {
        if (a.mbSlotIssuedAt === undefined && b.mbSlotIssuedAt === undefined)
          return 0;
        else if (a.mbSlotIssuedAt === undefined) return 1;
        else if (b.mbSlotIssuedAt === undefined) return -1;
        else {
          if (a.mbSlotIssuedAt.gt(b.mbSlotIssuedAt)) return 1;
          if (a.mbSlotIssuedAt.lt(b.mbSlotIssuedAt)) return -1;
          else return 0;
        }
      }
    });

  const categoryToCardInfosMap: { [category: string]: CardInfo[] } = {};
  for (const cardInfo of cardInfos) {
    const duration = cardInfo.position.bond.maxDurationAsEpochs;
    let category = network.durationToHotPoolCategory[duration.toNumber()];
    if (category !== undefined) {
      const cardInfos = categoryToCardInfosMap[category];
      if (cardInfos === undefined) {
        categoryToCardInfosMap[category] = [cardInfo];
      } else {
        categoryToCardInfosMap[category].push(cardInfo);
      }
    }
  }
  for (const [k, cardInfos] of Object.entries(categoryToCardInfosMap)) {
    const verifiedCardInfos = cardInfos.filter((card) => card.isVerified);
    const finalCardInfos = (
      verifiedCardInfos.length > 0 ? verifiedCardInfos : cardInfos
    ).slice(0, 6);
    categoryToCardInfosMap[k] = finalCardInfos;
  }

  const historicalData = useAppSelector(selectHistoricalData);
  const totalActiveBondsFormatted = formatAmount(
    Big(historicalData.activeBonds)
  );
  const totalActivePoolsFormatted = formatAmount(Big(cardInfos.length));
  const historicalBondsFormatted = formatAmount(
    Big(historicalData.historicalBondCount)
  );
  const tvlFormatted = formatValue(Big(historicalData.totalLovelace));
  const historicalTvlFormatted = formatValue(Big(historicalData.historicalTvl));

  const tvlTitleValuePairs = [
    {
      key: "HistoricalTVL",
      title: "Volume To Date",
      value: historicalTvlFormatted,
    },
    { key: "TVL", title: "TVL", value: tvlFormatted },
  ];

  const totalActivesTitleValuePairs = [
    {
      key: "Historical Bonds",
      title: "Bonds To Date",
      value: historicalBondsFormatted,
    },
    {
      key: "Total Active Bonds",
      title: "Total Active Bonds",
      value: totalActiveBondsFormatted,
    },
  ];

  return (
    <Container>
      <CustomTitle title="Dashboard" />
      <section className="grid md:grid-cols-4 gap-4 mb-20">
        <InsightCard icon="bar_chart" data={tvlTitleValuePairs} />
        <InsightCard icon="bar_chart" data={totalActivesTitleValuePairs} />
        <VerifyBond />
        <Card className="md:col-span-2 flex items-center">
          <div className="flex flex-col gap-8 w-full">
            <div className="flex justify-between items-center">
              <div className="bg-ui-background-sub border-ui-border-sub rounded-full p-4">
                <CustomIcon icon="gem" />
              </div>
              <Button variant="white" onClick={() => navigate("/bonds")}>
                Buy Bond
              </Button>
            </div>
            <div className="flex flex-col gap-1">
              <Text className="text-xl" weight="medium">
                Lend ADA
              </Text>
              <Text size="medium" tone="muted">
                Lend ADA to a borrower at a fixed interest rate and duration.
              </Text>
            </div>
          </div>
        </Card>
        <Card className="md:col-span-2">
          <div className="flex flex-col gap-8">
            <div className="flex justify-between items-center">
              <div className="bg-ui-background-sub border-ui-border-sub rounded-full p-4">
                <CustomIcon icon="plus" />
              </div>
              <Button
                variant="white"
                onClick={() => navigate("/bonds/issue-bond")}
              >
                Issue Bond
              </Button>
            </div>
            <div className="flex flex-col gap-1">
              <Text className="text-xl" weight="medium">
                Borrow ADA
              </Text>
              <Text size="medium" tone="muted">
                Borrow ADA from a lender at a fixed interest rate and duration.
              </Text>
            </div>
          </div>
        </Card>
      </section>
      <section>
        <div className="p-2 mb-8">
          <h2 className="text-xl font-semibold">Bonds🔥</h2>
        </div>
        {Object.values(network.durationToHotPoolCategory).map((category, i) => {
          const cardInfos = categoryToCardInfosMap[category];
          if (!cardInfos || cardInfos.length === 0) return null;
          return (
            <Fragment key={i}>
              <h3 className="text-xs px-2 font-medium mb-4 uppercase text-ui-base-purple">
                {category}
              </h3>
              <ProductCardsWrapper>
                {!!cardInfos &&
                  cardInfos?.map((item, i) => (
                    <ProductCard
                      {...item.cardData}
                      key={i}
                      onDetailsButtonClick={() => navigate(item.url)}
                    >
                      <Button
                        variant="white"
                        onClick={() => navigate(item.url)}
                        className="w-full"
                      >
                        Buy Bond
                        <FiArrowRight className="h-5 w-5" />
                      </Button>
                    </ProductCard>
                  ))}
              </ProductCardsWrapper>
            </Fragment>
          );
        })}
      </section>
    </Container>
  );
};

/* <VerifyBond /> */
