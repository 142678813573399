import { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ConnectWallet } from "./ConnectWallet";
import { WalletDetails } from "./WalletDetails";
import { SocialDropdown } from "./SocialDropdown";
import { MainMenu } from "./MainMenu";
import { useAppSelector } from "../../store/hooks";
import { selectWallet } from "../../store/slices/walletSlice";
import { LogoMenu } from "./LogoMenu";
import { LedgerHandle } from "../../store/hooks/ledgerWallet";

interface Props {
  ledgerHandle: LedgerHandle;
  txSigningMessage?: string;
}

export const Topbar: FC<Props> = ({ ledgerHandle, txSigningMessage }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const wallet = useAppSelector(selectWallet);

  const { connectLedger, disconnectLedger } = ledgerHandle;
  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/dashboard");
    }
  }, [location.pathname, navigate, wallet]);

  const isIlEPage = location.pathname.startsWith("/ile");

  return (
    <header className="max-w-7xl mx-auto flex items-center justify-between p-4 relative z-50 flex-wrap md:flex-nowrap">
      <LogoMenu />
      {isIlEPage ? null : <MainMenu />}
      <div className="flex items-center order-3">
        {wallet ? (
          <WalletDetails
            disconnectLedger={disconnectLedger}
            txSigningMessage={txSigningMessage}
          />
        ) : (
          <ConnectWallet connectLedger={connectLedger} isIlePage={isIlEPage} />
        )}
        <SocialDropdown />
      </div>
    </header>
  );
};
