import { FC, useEffect, useRef, useState } from "react";
// import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector, useInterval } from "../../store/hooks";
// import classnames from "classnames";
import styles from "./index.module.scss";
import { useNavigate } from "react-router-dom";
import { buyJboBonds, cancelJbo, getJbos, Jbo, JboTxResponse, makeJbo, makeRewardBatch, postRewardBatchScriptRefUtxo, selectJbos, selectMakeRewardBatchResponse, selectPostRewardBatchScriptRefUtxoResponse } from "../../bond/actions";
import { Button } from "../../components/Button";
import { selectWallet, updateWalletUtxosThunk } from "../../store/slices/walletSlice";
import { Wallet } from "../../bond/store-types";
import { Card, makeJboCardData } from "../../components/Card";
import { ReactSVG } from "react-svg";
import { v4 as uuidv4 } from "uuid";
import iconArrowRight from "../../assets/icons/li_arrow-right.svg";
import { UITypes } from "../../types/ui";
import makeJSONBigInt, {} from "json-bigint"
import { setAlert } from "../../store/slices/alertSlice";
import { jboTxResponseToAlert } from "../../bond/utils";
import { unsetModal } from "../../store/slices/modalsSlice";
import { SpinnerModal } from "../Modals/SpinnerModal";

const JSONBigInt = makeJSONBigInt({ useNativeBigInt: true, alwaysParseAsBig: true, constructorAction: 'preserve' })

interface Props { }

const JboComponent: FC<Props> = ({ }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useInterval('Jbos', async () => {
    console.log('Jbos Interval')
    dispatch(getJbos({ jboNftAssetClasses: [] }))
  }, 60000, [])

  const [isSpinnerModalOpen, setIsSpinnerModalOpen] = useState(false);

  const makeRewardBatchResponse = useAppSelector(selectMakeRewardBatchResponse)
  const postRewardBatchScriptRefUtxoResponse = useAppSelector(selectPostRewardBatchScriptRefUtxoResponse)
  const responses: { [key: string]: JboTxResponse | undefined } = {
    makeRewardBatchResponse,
    postRewardBatchScriptRefUtxoResponse
  }

  const prev = useRef(responses)
  console.log('hahhahaahh')
  console.log(responses)
  useEffect(() => {
    for (const [key, prevResponse] of Object.entries(prev.current)) {
      const currResponse = responses[key]
      console.log("makeRewardBatchResponse")
      console.log(prev.current)
      console.log(prevResponse)
      console.log(currResponse)
      if (prevResponse !== currResponse) {
        dispatch(setAlert(jboTxResponseToAlert(currResponse)))
        if (currResponse !== undefined && currResponse.tag === 'JboTxSuccess') {
          (async () => await dispatch(updateWalletUtxosThunk(null)))()
          dispatch(unsetModal())
        }
      }
      setIsSpinnerModalOpen(false)
      prev.current[key] = currResponse// { [key]: currResponse }
    }
  }, [dispatch, navigate, makeRewardBatchResponse, postRewardBatchScriptRefUtxoResponse])

  const wallet = useAppSelector(selectWallet)

  const jbos = useAppSelector(selectJbos)

  const makeRewardBatchButton = (wallet: Wallet) => () => {
    const makeRewardBatchRequest = {
      policyId: 'a56edc81b8c00cb46f12b256797a13dfb953d44e3173d5ef8f42992c',
      tokenName: '',
      rewardAccounts: [
        { paymentPkh: "6c82f79ddedf270e65e95a270b6eaf8cef7dc9a9e8578c4cae906d8f", amount: 1 },
        { paymentPkh: "6ad94d23437c604eba0a58fdccbc8416a5b5cf5ae5ef2d8fe3bd5624", amount: 1 }
      ]
    }
    setIsSpinnerModalOpen(true)
    dispatch(makeRewardBatch(makeRewardBatchRequest))
  }

  const postRewardBatchScriptRefUtxoButton = (wallet: Wallet) => () => {
    const postRewardBatchScriptRefUtxoRequest = {}
    setIsSpinnerModalOpen(true)
    dispatch(postRewardBatchScriptRefUtxo(postRewardBatchScriptRefUtxoRequest))
  }

 const makeJboButton = (wallet: Wallet) => () => {
    const makeJboRequest = {
      projectValue: { lovelace: 200_000_000n },
      ownerAddress: wallet.address,
      // bond token amounts (not project token)
      stakeBuckets: [
        ["stake_test1urdrh9gl6zp4n2zp0p3j0y3us0eyffwh4q955ngmk0yt4csqqpcuc", 20n] as [string, bigint],
        ["stake_test1uq3zuu8x3pdv2vzhg0jplyzsqxundkr89h68zmz0yy3qjfc9h803m", 20n] as [string, bigint],
      ],
      // per bond token. e.g. user buys 10 tokens they are buying a bond that pays 10 * perBond value + rewardsPerEpochPerBond
      perBond: { lovelace: 5_000_000n },
      // per bond. e.g. each epoch the borrower pays this much into the bond
      epoRewardPerBond: { lovelace: 2_000_000n },
      // bond tokens e.g. the minimum bond tokens bought must be >= minimumBonds
      minimumBonds: 1n,
      duration: 72n,
      buffer: 0n,
    }
    setIsSpinnerModalOpen(true)
    dispatch(makeJbo(makeJboRequest))
  }

  const gotoJboBonds = () => {
    navigate("/jbo/bonds")
  }
  const cancelJboButton = (jboNftPolicyId: string, jboNftTokenName: string) => () => {
    const cancelJboRequest = {
      jboNftPolicyId,
      jboNftTokenName,
      // jboNftPolicyId: "dcd3c5c5d9630376686086308b7e84d9c155b3452c94cf632fa6e257",
      // jboNftPolicyId: "53b6012a182e4f61d1ce118baece22719e0da9430077c254215ec5af",
      // jboNftTokenName: "000ec289f32b98fd8d2b6ccfc8199bbe20b93275b0a44005948e64ee41af0581",
      // jboNftTokenName: "00e0c9b656eac83611fea754ef10d951e10d91087a0d23a1c696e4e4ae5e2ee3",
    }
    dispatch(cancelJbo(cancelJboRequest))
  }
  const buyJboBondsButton = (jboNftPolicyId: string, jboNftTokenName: string) => () => {
    const buyJboBondsRequest = {
      jboNftPolicyId,
      jboNftTokenName,
      bondAmount: 1n,
    }
    dispatch(buyJboBonds(buyJboBondsRequest))
  }
  const jboCards = jbos.map(jbo => {
    const cardData = jboToCardData(jbo)
    // const url = ``
    return { cardData, jbo }
  })

  return (
    <div className={styles.container}>
      {!!jboCards && jboCards?.map((card) =>
      (
        <Card
          {...card.cardData}
          key={uuidv4()}
          onDetailsButtonClick={() => { }}
        >
          <Button
            size="xl"
            white
            className={styles.cardButton}
            onClick={cancelJboButton(card.jbo.policyId, card.jbo.tokenName)}
          >
            Cancel
            <ReactSVG
              src={iconArrowRight}
              className={styles.buttonArrowIcon}
            />
          </Button>
          <Button
            size="xl"
            white
            className={styles.cardButton}
            onClick={buyJboBondsButton(card.jbo.policyId, card.jbo.tokenName)}
          >
            Buy Bonds
            <ReactSVG
              src={iconArrowRight}
              className={styles.buttonArrowIcon}
            />
          </Button>
        </Card>
      )
      )}
      asldfjlasdjfljasdflj
      {wallet !== null
        ? <>
          <Button size="xl" className="primary" onClick={makeJboButton(wallet)} fullWidth>
            <p>Make Jbo</p>
          </Button>
          <Button size="xl" className="primary" onClick={makeRewardBatchButton(wallet)} fullWidth>
            <p>Make Reward Batch</p>
          </Button>
          <Button size="xl" className="primary" onClick={postRewardBatchScriptRefUtxoButton(wallet)} fullWidth>
            <p>Post Reward Batch Script Ref Utxo</p>
          </Button>
        </>
        : <></>
      }
      <Button size="xl" className="primary" onClick={gotoJboBonds} fullWidth>
        <p>Go to Jbo Bonds</p>
      </Button>
      <SpinnerModal open={isSpinnerModalOpen} message={"what the hell is this for"} />
    </div>
  );
};
// <Button size="xl" className="primary" onClick={cancelJboButton} fullWidth>
//   <p>Cancel Jbo</p>
// </Button>

// // txId#utxoIndex
// txOutRef: string  // !GYTxOutRef
// // 28 bytes as hex
// policyId: string // !GYMintingPolicyId
// // max 32 bytes utf8 encoded
// tokenName: string // !GYTokenName
// // bech32
// ownerAddress: string // !GYAddress
// stakeBuckets: [string, number][] // ![(GYStakeKeyHash, Integer)]
// perBond: GYValue // !GYValue
// epoRewardPerBond: GYValue // !GYValue
// minimumBonds: bigint // !Integer
// duration: bigint // !Epochs
// buffer: bigint // !Integer
// permissioned: string | undefined // !(Maybe GYPubKeyHash)
// state: JboState// !JboState
const jboToCardData = (jbo: Jbo): UITypes.Card.CardData => {
  // const pool = position.pool
  // const bond = position.bond
  // const verifiedName = getVerifiedName(verifiedNameMap, pool.poolTokenName, pool.poolSize)
  // const poolName =
  //   verifiedName !== undefined
  //   ? verifiedName.name
  //   : pool.poolTokenName

  // const airdropDetails = makeFutureAirdropDetails(verifiedName)
  const jboName = jbo.tokenName // `${jbo.policyId}.${jbo.tokenName}`
  const params = {
    name: jboName
  }
  return makeJboCardData(params, [
    {
      name: "TxOutRef",
      value: jbo.txOutRef,
      tooltip: ''
    },
    {
      name: "PolicyId",
      value: jbo.policyId,
      tooltip: ''
    },
    {
      name: "Value",
      value: JSONBigInt.stringify(jbo.value),
      tooltip: ''
    },
    {
      name: "TokenName",
      value: jbo.tokenName,
      tooltip: ''
    },
    {
      name: "OwnerAddress",
      value: jbo.ownerAddress,
      tooltip: ''
    },
    {
      name: "StakeBuckets",
      value: JSONBigInt.stringify(jbo.stakeBuckets),
      tooltip: ''
    },
    {
      name: "PerBond",
      value: JSONBigInt.stringify(jbo.perBond),
      tooltip: ''
    },
    {
      name: "EpoRewardsPerBond",
      value: JSONBigInt.stringify(jbo.epoRewardPerBond),
      tooltip: ''
    },
    {
      name: "MinimumBonds",
      value: jbo.minimumBonds.toString(),
      tooltip: ''
    },
    {
      name: "Duration",
      value: jbo.duration.toString(),
      tooltip: ''
    },
    {
      name: "Buffer",
      value: jbo.buffer.toString(),
      tooltip: ''
    },
    {
      name: "Permissioned",
      value: JSONBigInt.stringify(jbo.permissioned),
      tooltip: ''
    },
    {
      name: "State",
      value: JSONBigInt.stringify(jbo.state),
      tooltip: ''
    },
  ])
}

export default JboComponent;
