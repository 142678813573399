import { HARDENED } from "@cardano-foundation/ledgerjs-hw-app-cardano";
import classnames from "classnames";
import { FC, useContext, useState } from "react";
import eternl from "../../../assets/icons/eternl.png";
import exodus from "../../../assets/icons/exodus.svg";
import flint from "../../../assets/icons/flint.svg";
import gero from "../../../assets/icons/gero.svg";
import lace from "../../../assets/icons/lace.svg";
import ledger from "../../../assets/icons/ledger.svg";
import lode from "../../../assets/icons/lode.svg";
import nami from "../../../assets/icons/nami.svg";
import nufi from "../../../assets/icons/nufi.svg";
import typhon from "../../../assets/icons/typhon.svg";
import vespr from "../../../assets/icons/vespr.svg";
import buttonStyles from "../../../components/Button/index.module.scss";
import { Modal } from "../../../components/Modal";
import { useAppDispatch } from "../../../store/hooks";
import { LedgerTransportType } from "../../../store/hooks/ledgerWallet";
import { setWalletByProvider } from "../../../store/slices/walletSlice";
import { cn } from "../../../utils/tailwind";
import { WebsocketContext } from "../../../websocket";
import { ItemSelector } from "./blocks/ItemSelector";
import styles from "./index.module.scss";
import { Card } from "../../../components/ui/card";
import { Text } from "../../../components/ui/typography";
import { Button } from "../../../components/ui/button";

type Props = {
  connectLedger: (transportType: LedgerTransportType, account: number) => void;
  fullWidth?: boolean;
  isIlePage?: boolean;
};

type HardwareTransportType = "WebHID" | "WebUSB" | "Web Bluetooth";

export const ConnectWallet: FC<Props> = ({
  fullWidth,
  connectLedger,
  isIlePage,
}) => {
  const [isOpen, setOpen] = useState(false);
  const [isConnectLedgerOpen, setConnectLedgerOpen] = useState(false);
  const [hardwareTransportType, setHardwareTransportType] =
    useState<HardwareTransportType>("WebHID");
  const [hardwareAccount, setHardwareAccount] = useState(0);
  const dispatch = useAppDispatch();
  const ws = useContext(WebsocketContext);

  const dispatchSelectWallet = (walletName: string) => async () => {
    // TODO: not convinced there aren't race conditions here
    dispatch(setWalletByProvider({ name: walletName, ws }));
    // await dispatch(updateWalletUtxosThunk(null))
    // await dispatch(getWrittenBondHistoriesThunk())
    // await dispatch(getUserBondHistoriesThunk())
    // dispatch(alertInDangerBondsThunk(null))
  };
  const [isChecked, setIsChecked] = useState(false);

  const derivationPathRoot = "m/1852'/1815'/";
  const inputToDerivationPath = (value: string) => {
    if (value.startsWith(derivationPathRoot)) {
      const pathMatch = value.match(
        new RegExp("^" + derivationPathRoot + "([0-9]+)")
      );
      if (pathMatch == null) {
        setHardwareAccount(0);
        return;
      }

      const account = pathMatch[1];
      if (value === derivationPathRoot + account) {
        setHardwareAccount(Number(account.slice(0, -1)));
      } else {
        const newAccount = Number(account + value.split("'").slice(-1)[0]);
        if (isNaN(newAccount)) setHardwareAccount(Number(account));
        else setHardwareAccount(Math.min(newAccount, HARDENED - 1));
      }
    }
  };

  const transportToLedgerTransport = (
    key: HardwareTransportType
  ): LedgerTransportType => {
    return {
      WebHID: "LedgerTransportWebHid",
      WebUSB: "LedgerTransportWebUsb",
      "Web Bluetooth": "LedgerTransportBluetooth",
    }[key] as LedgerTransportType;
  };

  return (
    <>
      <Button
        className={cn(fullWidth && "w-full")}
        onClick={() => setOpen(!isOpen)}
      >
        Connect Wallet
      </Button>
      <Modal open={isOpen} blur={true} onClose={() => setOpen(false)}>
        <div>
          <h4 className="text-xl font-normal mb-6">Select Wallet</h4>
          <Card className="flex gap-2 items-start">
            <input
              className="mt-1"
              type="checkbox"
              defaultChecked={isChecked}
              onClick={() => setIsChecked(!isChecked)}
            />
            {isIlePage ? (
              <p>
                THE OPTIM ILE INTERFACE IS NOT AVAILABLE TO PERSONS OR ENTITIES
                WHO RESIDE IN, ARE LOCATED IN, ARE INCORPORATED IN, OR HAVE A
                REGISTERED OFFICE IN THE UNITED STATES OF AMERICA OR CANADA
                (COLLECTIVELY, “BLOCKED PERSONS”). <br />
                <br />
                MOREOVER, NO SERVICES ARE OFFERED TO PERSONS OR ENTITIES WHO
                RESIDE IN, ARE CITIZENS OF, ARE LOCATED IN, ARE INCORPORATED IN,
                OR HAVE A REGISTERED OFFICE IN ANY RESTRICTED TERRITORY (AS
                DEFINED BELOW, AND ANY SUCH PERSON OR ENTITY FROM A RESTRICTED
                TERRITORY, A “RESTRICTED PERSON”). WE DO NOT MAKE EXCEPTIONS;
                THEREFORE, IF YOU ARE A BLOCKED PERSON, THEN DO NOT ATTEMPT TO
                USE THE OPTIM ILE INTERFACE AND IF YOU ARE A RESTRICTED PERSON,
                THEN DO NOT ATTEMPT TO USE ANY OF THE SERVICES. USE OF A VIRTUAL
                PRIVATE NETWORK (“VPN”) TO CIRCUMVENT THE RESTRICTIONS SET FORTH
                HEREIN IS PROHIBITED. <br />
                <br />
                You must not be a resident, citizen or agent of, or incorporated
                in, and do not have a registered office in Iran, Cuba, North
                Korea, Syria, Myanmar (Burma), or any other country or region
                that is the subject of comprehensive country-wide or region-wide
                economic sanctions by the United States (collectively,
                “Restricted Territories”) <br />
                <br />
                You are not the subject of economic or trade sanctions
                administered or enforced by any governmental authority or
                otherwise designated on any list of prohibited or restricted
                parties (including the list maintained by the Office of Foreign
                Assets Control of the U.S. Department of the Treasury)
                (collectively, “Sanctioned Person”); you do not intend to
                transact with any Restricted Person or Sanctioned Person. <br />
                <br />
                You do not, and will not, use a VPN or any other privacy or
                anonymization tools or techniques to circumvent, or attempt to
                circumvent, any restrictions. Your access to digital asset
                services is not prohibited by and does not otherwise violate or
                assist you to violate any domestic or foreign law, rule,
                statute, regulation, by-law, order, protocol, guideline,
                published or in force that applies to or is otherwise intended
                to govern or regulate any person, property, transaction,
                activity, event or other matter that has been issued by any
                domestic or foreign federal, provincial or state, municipal,
                local or other governmental, regulatory, judicial or
                administrative authority having jurisdiction over Optim. <br />
                <br />
                THE ILE SITE AND ALL INFORMATION CONTAINED ON THE SITE, ARE MADE
                ACCESSIBLE OR AVAILABLE ON AN “AS IS” AND “AS AVAILABLE” BASIS.
                YOU EXPRESSLY AGREE THAT USE OF THE ILE SITE OR THE PROTOCOL IS
                AT YOUR SOLE RISK. TO THE FULLEST EXTENT ALLOWED BY APPLICABLE
                LAW, NONE OF OPTIM LABS, ITS SUBSIDIARIES, AFFILIATES, AND
                PARTNERS, OR ANY DEVELOPER, EMPLOYEE, AGENT OR LICENSOR
                ASSOCIATED WITH ANY OF THEM, WARRANT THAT USE OF THE SITE WILL
                BE UNINTERRUPTED, FULLY SECURE, VIRUS- OR ERROR-FREE, NOR DO
                THEY MAKE ANY WARRANTY AS TO THE RESULTS THAT MAY BE OBTAINED
                FROM USE OF THE SITE OR THE PROTOCOL. EACH OF THE FOREGOING
                HEREBY DISCLAIMS ANY AND ALL REPRESENTATIONS, WARRANTIES AND
                CONDITIONS, WHETHER EXPRESS OR IMPLIED, AS TO THE SITE OR ANY
                INFORMATION CONTAINED ON THE SITE, INCLUDING, BUT NOT LIMITED
                TO, THOSE OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY,
                SUITABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AS WELL AS
                WARRANTIES IMPLIED FROM A COURSE OF PERFORMANCE OR COURSE OF
                DEALING. <br />
                <br />
                IN NO EVENT SHALL OPTIM LABS, ITS SUBSIDIARIES, AFFILIATES, AND
                PARTNERS, OR ANY DEVELOPER, EMPLOYEE, AGENT OR LICENSOR
                ASSOCIATED WITH ANY OF THEM, BE LIABLE FOR ANY DAMAGES ARISING
                OUT OF OR RELATED TO: (I) YOUR USE OF OR INABILITY TO USE THE
                PROTOCOL, OR THE SITE, OR INFORMATION CONTAINED IN THE SITE,
                (II) YOUR INTERACTIONS WITH OTHER USERS, OR (III) THESE USE
                TERMS; INCLUDING BUT NOT LIMITED TO (A) DIRECT, INDIRECT,
                INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY
                KIND, AND (B) LOSS OF REVENUES, PROFITS, GOODWILL,
                CRYPTOCURRENCIES, TOKENS OR ANYTHING ELSE OF VALUE
              </p>
            ) : (
              <Text>
                By connecting your wallet, you agree to our{" "}
                <a
                  className={styles.headerLink}
                  href="https://optim.finance/disclaimer"
                >
                  Disclaimer
                </a>{" "}
                and our{" "}
                <a
                  className={styles.headerLink}
                  href="https://optim.finance/privacy-policy"
                >
                  Privacy Policy
                </a>
                .
              </Text>
            )}
          </Card>
          <ul className="my-6 flex flex-col gap-2">
            <li>
              <Button
                variant="secondary"
                className="w-full rounded-xl justify-start"
                onClick={() => {
                  setConnectLedgerOpen(true);
                  setOpen(false);
                }}
                disabled={!isChecked}
              >
                <span className={styles.icon}>
                  <img
                    src={ledger}
                    className={buttonStyles.invertOnHover}
                    alt="Ledger"
                  />
                </span>
                <span>Ledger</span>
              </Button>
            </li>
            <li>
              <Button
                variant="secondary"
                className="w-full rounded-xl justify-start"
                onClick={dispatchSelectWallet("nami")}
                disabled={!isChecked}
              >
                <span className={styles.icon}>
                  <img src={nami} alt="Nami" />
                </span>
                <span>Nami</span>
              </Button>
            </li>
            <li>
              <Button
                variant="secondary"
                className="w-full rounded-xl justify-start"
                onClick={dispatchSelectWallet("eternl")}
                disabled={!isChecked}
              >
                <span className={styles.icon}>
                  <img src={eternl} alt="Eternl" />
                </span>
                <span>Eternl</span>
              </Button>
            </li>
            <li>
              <Button
                variant="secondary"
                className="w-full rounded-xl justify-start"
                onClick={dispatchSelectWallet("flint")}
                disabled={!isChecked}
              >
                <span className={styles.icon}>
                  <img src={flint} alt="Flint" />
                </span>
                <span>Flint</span>
              </Button>
            </li>
            <li>
              <Button
                variant="secondary"
                className="w-full rounded-xl justify-start"
                onClick={dispatchSelectWallet("gerowallet")}
                disabled={!isChecked}
              >
                <span className={styles.icon}>
                  <img src={gero} alt="Gero" />
                </span>
                <span>Gero</span>
              </Button>
            </li>
            <li>
              <Button
                variant="secondary"
                className="w-full rounded-xl justify-start"
                onClick={dispatchSelectWallet("typhoncip30")}
                disabled={!isChecked}
              >
                <span className={styles.icon}>
                  <img src={typhon} alt="Typhon" />
                </span>
                <span>Typhon</span>
              </Button>
            </li>
            <li>
              <Button
                variant="secondary"
                className="w-full rounded-xl justify-start"
                onClick={dispatchSelectWallet("LodeWallet")}
                disabled={!isChecked}
              >
                <span className={styles.icon}>
                  <img src={lode} alt="Lode" />
                </span>
                <span>Lode</span>
              </Button>
            </li>
            <li>
              <Button
                variant="secondary"
                className="w-full rounded-xl justify-start"
                onClick={dispatchSelectWallet("exodus")}
                disabled={!isChecked}
              >
                <span className={styles.icon}>
                  <img src={exodus} alt="Exodus" />
                </span>
                <span>Exodus</span>
              </Button>
            </li>
            <li>
              <Button
                variant="secondary"
                className="w-full rounded-xl justify-start"
                onClick={dispatchSelectWallet("vespr")}
                disabled={!isChecked}
              >
                <span className={styles.icon}>
                  <img
                    src={vespr}
                    className={buttonStyles.invertOnHover}
                    alt="Vespr"
                  />
                </span>
                <span>Vespr</span>
              </Button>
            </li>
            <li>
              <Button
                variant="secondary"
                className="w-full rounded-xl justify-start"
                onClick={dispatchSelectWallet("lace")}
                disabled={!isChecked}
              >
                <span className={styles.icon}>
                  <img
                    src={lace}
                    className={buttonStyles.invertOnHover}
                    alt="Lace"
                  />
                </span>
                <span>Lace</span>
              </Button>
            </li>
            <li>
              <Button
                variant="secondary"
                className="w-full rounded-xl justify-start"
                onClick={dispatchSelectWallet("nufi")}
                disabled={!isChecked}
              >
                <span className={styles.icon}>
                  <img
                    src={nufi}
                    className={buttonStyles.invertOnHover}
                    alt="Nufi"
                  />
                </span>
                <span>Nufi</span>
              </Button>
            </li>
          </ul>
        </div>
      </Modal>
      <Modal
        open={isConnectLedgerOpen}
        blur={true}
        onClose={() => setConnectLedgerOpen(false)}
      >
        <div>
          <h4 className={styles.title}>Connect Ledger</h4>
          <ItemSelector
            items={["WebHID", "WebUSB", "Web Bluetooth"]}
            onSelect={(item: string) => {
              setHardwareTransportType(item as HardwareTransportType);
            }}
            selectedItem={hardwareTransportType}
          />
          <hr />
          <h5>Specific Account</h5>
          <div className="relative">
            <input
              placeholder="0"
              className={classnames(styles.box, styles.inputBox)}
              value={derivationPathRoot + hardwareAccount + "'"}
              onChange={(e) => inputToDerivationPath(e.target.value)}
            />
            <Button
              className={styles.floatingConnectButton}
              onClick={() => {
                connectLedger(
                  transportToLedgerTransport(hardwareTransportType),
                  hardwareAccount
                );
                setConnectLedgerOpen(!isConnectLedgerOpen);
              }}
            >
              Connect
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
